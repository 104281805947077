const routes = [{
	path: "/Admin/Dashboard",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./Index.vue")
},
{
	path: "/Admin/Dashboard/MonthlySummary",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./MonthlySummary.vue")
},
{
	path: "/Admin/Dashboard/Funnel",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./FunnelView.vue")
},
{
	path: "/Admin/Dashboard/Pivot",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./Pivot.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["BusinessAdmin"]
	};
}
);

export default routes;
