<template>
	<v-card class="elevate-5 pa-5 ma-5 text-center">
		<h1 class="nutriyou-h1">{{ $t("404") }}</h1>
	</v-card>
</template>
<script>
export default {
}
</script>
<i18n>
{
	"it": { "404": "404 Pagina non trovata" },
	"en": { "404": "404 Page not found" }
}
</i18n>
