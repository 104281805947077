<template>
	<v-autocomplete
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', $event)"
		:loading="loading"
		:label="$attrs.label || $t('label_Comune')"
		:items="items"
		item-text="Name"
		item-value="ComuneId"
		:search-input.sync="search"
	></v-autocomplete>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			CitiesService: new CrudClient("ItalianCities"),
			loading: false,
			items: [],
			search: null,
		};
	},

	props: {
		ProvinciaAbbreviation: {
			type: String,
			default: null,
		},
		value: {
			type: Number,
			default: null,
		},
	},

	watch: {
		async search(val) {
			this.$log.debug("Watch search: " + val);
			val && val !== this.value && val.length > 1 && (await this.doSearch(val));
		},

		value: {
			immediate: true,
			handler: async function (newVal) {
				if (!newVal) return;
				// Programmatic value set
				if (!this.items.find((i) => i.ComuneId === newVal)) {
					try {
						this.loading = true;
						const res = await this.CitiesService.GetSelectedFields(
							newVal,
							"Name, ComuneId",
							null,
							false
						);

						this.search = res.Name;
					} catch (error) {
						this.snackError({ Text: this.$t("common.error.cannotLoadData") });
						this.$captureError(error);
					} finally {
						this.loading = false;
					}
				}
			},
		},
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async doSearch(val) {
			try {
				this.loading = true;
				this.items = (
					await this.CitiesService.GetPaged({
						limit: 10,
						skip: 0,
						orderBy: "Name",
						filter:
							`Name:sw:${val}` +
							(this.ProvinciaAbbreviation == null
								? ""
								: `;ProvinciaAbbreviation:${this.ProvinciaAbbreviation}`),
						fields: "Name, ComuneId",
						addApplicationId: false,
					})
				).Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			}

			this.loading = false;
		},
	},
};
</script>

<i18n>
{
    "it":{ "label_Comune": "Comune" }
}
</i18n>
