<template>
	<div>
		<v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
		<v-card v-if="unsubscribeCompleted">
			<v-card-text>
				<div class="text-center">
					<v-icon size="60" color="green">fas fa-check-circle</v-icon>
				</div>
				<h2 class="nutriyou-h3 text-center">Rimozione riuscita</h2>
				<div class="text-center">
				È stata correttamente annullata l’iscrizione a tutte le mailing list e in futuro non dovresti più ricevere messaggi.
				</div>
				<!-- <div class="text-center mt-5 font-weight-bold text-underline">
					Clicca qui per iscriverti nuovamente a nostro newsletter.
				</div> -->
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	data() {
		return {
			loading: false,
			unsubscribeCompleted: false
		}
	},
	created() {
		this.PatientsService = new CrudClient("Patients");
	},
	async mounted() {
		try {
			this.loading = true
			await this.PatientsService.Post(
				`Unsubscribe/${this.$route.params.userId}`,
				null,
				null,
				false
			);
			this.unsubscribeCompleted = true;
		} catch (error) {
			this.$captureError(error);
			this.snackError({ Text: "Operazione non riuscita" });
		} finally {
			this.loading = false;
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"])
	}
}
</script>

<style>

</style>
