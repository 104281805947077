var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isSystemMessage ? 'systemMessage' : 'textMessage',attrs:{"id":_vm.message.sid}},[(!_vm.message.attributes.MessageType)?_c('div',{staticClass:"pa-2 mb-1 messageBox Selectable",class:{
			'blue-grey lighten-4': _vm.message.author === _vm.userId,
			'green lighten-4': _vm.message.author !== _vm.userId,
		}},[_vm._v(" "+_vm._s(_vm.message.body)+" ")]):_vm._e(),(_vm.message.attributes.MessageType === 'SendFile')?_c('SendFileWidget',{attrs:{"message":_vm.message,"userId":_vm.userId,"view":_vm.viewAs}}):_vm._e(),_c('BookingCreated',{attrs:{"message":_vm.message,"view":_vm.viewAs}}),_c('BookingModified',{attrs:{"message":_vm.message,"view":_vm.viewAs}}),_c('BookingCancelledWidget',{attrs:{"message":_vm.message,"view":_vm.viewAs}}),_c('BookingCompleted',{attrs:{"message":_vm.message,"view":_vm.viewAs}}),_c('PaymentCompleted',{attrs:{"message":_vm.message,"view":_vm.viewAs}}),_c('BookingExpired',{attrs:{"message":_vm.message,"view":_vm.viewAs}}),_c('div',{staticClass:"MessageTimestamp grey--text text--darken-1 mb-2"},[_vm._v(" "+_vm._s(_vm._f("formatLocalDateTime")(_vm.message.timestamp))+" "),(
			_vm.message.liveConversation &&
				_vm.viewAs === 'sent' &&
				_vm.lastSeenMessageId &&
				_vm.message.index <= _vm.lastSeenMessageId
			)?_c('v-icon',{staticClass:"blue--text text--lighten-1",attrs:{"size":"12"}},[_vm._v("fas fa-check")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }