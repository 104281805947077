import Vue from "vue";

import numeral from "numeral";
import "numeral/locales/it";

import moment from "moment";

numeral.locale("it");
moment.locale("it");

Vue.filter("formatLocalDateTime", function(date) {
	if (!date) return "";

	const m = moment.utc(date).local();
	return m.format("L") + " " + m.format("LT");
});

Vue.filter("formatLocalDate", function(date) {
	if (!date) return "";
	const m = moment.utc(date).local();
	return m.format("L");
});

Vue.filter("formatDate", function(date) {
	if (!date) return "";
	const m = moment(date);
	return m.format("L");
});

Vue.filter("formatDateTime", function(date) {
	if (!date) return "";
	const m = moment(date);
	return m.format("L") + " " + m.format("LT");
});

Vue.filter("getInitial", function(value) {
	if (!value) return "";
	value = value.toString();
	return value.charAt(0).toUpperCase();
});

Vue.filter("emptyField", function(value, placeholder = "-") {
	if (!value) return placeholder;
	return value;
});

Vue.filter("capitalizeFirstLetter", function(value) {
	if (!value) return ""
	value = value.toString()
	return value.charAt(0).toUpperCase();
});

Vue.filter("formatCurrency", function(value, currencyId) {
	if (value === undefined) return ""
	let currency = "";

	if (!currencyId) currencyId = "EUR"
	if (currencyId === "EUR") { currency = "€" }

	return currency + " " + numeral(value).format("0,0.00")
});

Vue.filter("formatCurrencyAvoidDecimal", function(value, currencyId) {
	if (!value) return ""
	let currency = "";

	if (!currencyId) currencyId = "EUR"
	if (currencyId === "EUR") { currency = "€" }

	return currency + " " + numeral(value).format("0,0")
});

Vue.filter("formatFileSize", function(value) {
	if (value === undefined) return ""
	const i = Math.floor(Math.log(value) / Math.log(1024));
	return (value / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i];
});
