<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>	</v-breadcrumbs>
		<div class="mx-5">
			<template v-if="showForm">
				<h2 class="nutriyou-h2 primary--text mb-1 text-center">Hai qualche dubbio o domanda?</h2>
				<div class="text-h5 mb-3 text-center"></div>
			</template>
			<div style="max-width: 600px; margin-left: auto; margin-right: auto;">
				<SimpleFormCard v-if="showForm" :mode="1" ref="formContactUs" :formContent="formContactUs"
					:metadata="metadata" :metadataLoaded="metadataLoaded" @submitForm="SendMessage"
					:showCancelButton="false" :locali18n="$i18n" :addButtonProps="{ color: 'primary' }">
					<template slot="card-title">
						<div class="nyblue white--text px-5 py-3">
							<span class="headline" style="word-break: normal">
								Scrivici un messaggio</span>
						</div>
					</template>
					<template slot="card-beforeForm"> </template>
					<template slot="btnAdd">
						<span>{{ $t("common.send") }}</span>
						<v-icon right dark>fas fa-envelope</v-icon>
					</template>
				</SimpleFormCard>

				<div v-if="!showForm" class="text-center py-5">
					<v-icon color="success" size="80">far fa-check-circle</v-icon>
					<p class="headline mt-5">{{ $t("contactMessage.success") }}</p>
					<p class="title">{{ $t("contactMessage.successLong") }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	components: { SimpleFormCard },
	data: () => ({
		formContactUs: new CrudFormData("formContactUs", [
			"Name",
			"Email",
			"PhoneNo",
			{
				type: "v-textarea",
				FieldName: "Message",
				autoGrow: true,
			},
		]),
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			},
		],
		showForm: true,
		metadataLoaded: true,

		metadata: {
			Name: {
				ClientValidators: "required|max:100",
				MaxLength: 100,
			},
			Email: {
				ClientValidators: "required|email|max:50",
				MaxLength: 50,
			},
			PhoneNo: {
				ClientValidators: "max:50",
				MaxLength: 50,
			},
			Message: {
				ClientValidators: "required|max:500",
				MaxLength: 500,
			},
		},
	}),
	computed: {
		...mapGetters(["isUserAuthenticated"]),
	},
	created() {
		this.ContactFormService = new CrudClient("ContactForm");
	},
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("common.contactUs"),
			disabled: true,
			exact: true,
			to: "/ContactUs",
		});
		// if (this.isUserAuthenticated) {
		// 	//remove first 3 elements
		// 	this.formContactUs.Fields.splice(0, 3);
		// }
	},
	methods: {
		...mapActions(["snackError"]),
		async SendMessage(data) {
			try {
				this.formContactUs.FormLoading = true;
				await this.ContactFormService.Post(null, data, null, false);

				this.showForm = false;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("contactMessage.errorCannotSend") });
			} finally {
				this.formContactUs.FormLoading = false;
			}
		},
	},
};
</script>

<i18n>
	{
		"it":
		{
			"pageTitle": "Contattaci",
			"pageMetaDescription": "Contattaci - invia il tuo messaggio",
			"formContactUs": {
				"Name": "Nome",
				"Email": "Email",
				"PhoneNo": "Telefono",
				"Message": "Messaggio"
			},
			"contactMessage.success":"Messaggio è stato inviato",
			"contactMessage.successLong":"Abbiamo ricevuto la tua richiesta e ti rispondiamo nel minor tempo possibile per fornirti tutte le informazioni di cui hai bisogno.",
			"contactMessage.errorCannotSend": ""

		}
	}
</i18n>
