<template>
	<v-container fluid class="ma-0 pa-0">
		<v-row class="py-0 px-2 pb-sm-3 ma-0" id="messageArea">
      <v-progress-linear indeterminate v-if="CurrentConversationView.CreatingTwilioConversation"></v-progress-linear>
			<v-col cols="9" sm="8" lg="10" class="pr-2 py-0">
				<v-textarea v-model="content" ref="textarea" auto-grow rows="1" v-on:keydown="keydownHandler"
					placeholder="Il tuo messaggio..." class="mt-0" :disabled="sendingMessage || uploadingFiles"
                   >
					<template v-slot:prepend>
						<v-menu top right v-model="showEmojiMenu" :close-on-content-click="false" :offset-y="true"
							:max-width="'90%'">
							<template v-slot:activator="{ on }">
								<v-icon v-on="on">far fa-smile</v-icon>
							</template>
							<v-card class="elevation-5 pa-2">
								<EmojiPicker :native="true" :perLine="7" :showSearch="false" :showCategories="false"
									:showPreview="false" @select="addEmoji" :infiniteScroll="false"
									:include="['people']" :i18n="{ categories: { people: '' } }" />
							</v-card>
						</v-menu>
						<v-icon v-show="currentConversationFiles?.length === 0" class="ml-2" @click="pickFile()"
            >far fa-paperclip</v-icon>
					</template>
				</v-textarea>
			</v-col>
			<v-col cols="3" sm="4" lg="2" class="py-0 pb-sm-4 d-flex align-center align-sm-end">
				<v-btn block color="info" class="sendButton" @click="sendMessage"
               :disabled="CurrentConversationView.CreatingTwilioConversation"
					:loading="sendingMessage || uploadingFiles">
					<template v-if="!$vuetify.breakpoint.xs">{{ $t("common.send") }}</template>
					<v-icon right>fas fa-paper-plane</v-icon>
				</v-btn>
			</v-col>

			<v-col cols="12" class="py-0" v-show="currentConversationFiles?.length > 0">
				<v-row class="py-0 d-flex align-end uploadFiles">
					<v-col cols="12" class="py-0">
						<v-file-input chips show-size multiple ref="filePicker"
                  :value="currentConversationFiles"
                  label="File input"
                  @change="onFileSelected($event)"
                          @click:clear="ClearSelectedFiles"
                  :disabled="uploadingFiles"

            ></v-file-input>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { Picker } from "emoji-mart-vue";
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	components: {
		EmojiPicker: Picker
	},
	props: {
		CurrentConversationView: Object,
		uploadingFiles: Boolean,
		externallyUploadedFiles: Array
	},
	data() {
		return {
			chatService: new CrudClient("Chat"),
			maxFileSize: 10,
			metaPixelContactEventSent: false,
			content: "",
			sendingMessage: false,
			showEmojiMenu: false,
		}
	},
	computed: {
		...mapGetters([
			"isUserInRole",
			"conversationSelectedFiles"
		]),
		currentConversationFiles() {
			if (!this.ConversationSid) { return null; }

			return this.conversationSelectedFiles[this.ConversationSid];
		},
		ConversationSid() {
			return this.CurrentConversationView.ConversationSid;
		}
	},
	methods: {
		...mapActions([
			"snackSuccess",
			"snackError",
			"LoadUserPublicProfile",
			"AddConversationSelectedFiles",
			"ClearConversationSelectedFiles"
		]),
		ClearSelectedFiles() {
			this.ClearConversationSelectedFiles(this.ConversationSid)
		},
		async sendMessage() {
			if (this.content !== "") {
				this.sendingMessage = true;
				try {
					await this.CurrentConversationView.TwilioConversation.conversation.sendMessage(this.content);

					// send pixel event
					if (this.isUserInRole("Patient") && !this.metaPixelContactEventSent) {
						this.metaPixelContactEventSent = true;
						window.fbq("track", "Contact");
					}
					this.content = "";
				} catch (err) {
					this.$captureError(err);
				} finally {
					this.sendingMessage = false;
				}
			}

			this.$emit("uploadFiles");
			this.files = [];
			if (this.$refs.textarea) {
				this.$refs.textarea.focus();
			}
		},
		async keydownHandler(e) {
			if (e.keyCode === 13 && !e.shiftKey) {
				e.preventDefault();
				await this.sendMessage();
			} else {
				if (!this.CurrentConversationView.CreatingTwilioConversation) {
					await this.CurrentConversationView.TwilioConversation.conversation.typing();
				}
			}
		},

		pickFile() {
			const filePicker = document.querySelector(".chatWindow .uploadFiles input");
			filePicker.click();
		},

		onFileSelected(files) {
			this.$emit("filesSelected", files);
		},

		async addEmoji(emoji) {
			const textarea = this.$refs.textarea.$refs.input;
			const cursorPosition = textarea.selectionEnd;

			const start = this.content.substring(0, textarea.selectionStart);
			const end = this.content.substring(textarea.selectionStart);
			this.content = start + emoji.native + end;
			textarea.focus();
			this.$nextTick(() => {
				textarea.selectionEnd = cursorPosition + emoji.native.length;
			});

			if (!this.CurrentConversationView.CreatingTwilioConversation) {
				await this.CurrentConversationView.TwilioConversation.conversation.typing();
			}
		}
	}
}
</script>
<style lang="scss">
@import "@/assets/css/variables.scss";
.chatWindow {
		#messageArea {
			flex: initial;
			position: relative;
			border-top: 1px solid map-get($grey, "lighten-2");
			background-color: white;

			@media #{map-get($display-breakpoints, "xs-only")} {
				width: 100vw;
			}

			.emoji-mart {
				&-scroll {
					overflow-x: hidden;
					padding-left: 3px !important;
				}

				&-emoji {
					&>span {
						font-size: 18px !important;
					}
				}
			}

			.v-textarea {
				max-height: 300px;
				overflow-y: auto;
				align-items: flex-end;

				textarea {
					max-height: 280px;
					overflow-y: auto;
				}
			}

			.uploadFiles {
				@media #{map-get($display-breakpoints, 'xs-only')} {
					flex-wrap: wrap;
				}
			}

			.v-input {
				&__prepend-outer {
					margin-bottom: 20px;
				}
			}
		}
	}
</style>
