const AccountChangePassword = () => import(/* webpackChunkName: "AccountViews" */ "./ChangePassword.vue");
const AccountProfile = () => import(/* webpackChunkName: "AccountViews" */ "./Profile/index.vue");

export default [{
	path: "/Account/Profile",
	component: AccountProfile,
	meta: {
		requiresAuth: true
	}
},
{
	path: "/Account/ChangePassword",
	component: AccountChangePassword,
	meta: {
		requiresAuth: true
	}
}
];
