<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>
	<h1 class="nutriyou-h1">Nutriyou Blog</h1>

	<StaticContentGrid :ContentIds="[1,2,3]"></StaticContentGrid>
	</div>
</template>

<script>
import StaticContentGrid from "./StaticContentGrid.vue";
// src\views\Admin\Content\components\StaticContent\Viewer.vue
// import StaticContentViewer from "@/views/Admin/Content/components/StaticContent/Viewer";
// import CrudClient from "@/services/CrudClient/";

export default {
	name: "StaticContentPage",
	components: { StaticContentGrid },
	data: () => ({
		Contents: [],
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			}
		],
	}),
	async mounted() {
		this.breadcrumbsItems.push(
			{
				text: this.$t("common.blog"),
				disabled: true,
				exact: true,
				to: "/Blog",
			},
		);
	},
};
</script>
