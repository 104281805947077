<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="800px" @keydown.esc="CloseDialog">
		<v-card>
			<div class="primary white--text px-5 py-3 d-flex align-center justify-space-between">
				<span class="headline" style="word-break: normal">{{
					$t("dialogHeader")
					}}</span>
				<v-btn icon dark @click="CloseDialog">
					<v-icon>close</v-icon>
				</v-btn>
			</div>
			<v-card-text>
				<BookingTypePicker v-model="bookingTypeId" @change="onBookingTypeChanged"></BookingTypePicker>
				<DateTimePicker v-model="bookingTime"></DateTimePicker>
				<v-text-field prepend-icon="fas fa-clock" type="number" v-model="duration" disabled
					:label="$t('common.duration')" readonly></v-text-field>
				<v-text-field prepend-icon="fas fa-euro-sign" type="number" disabled :label="$t('common.price')"
					v-model="price" readonly></v-text-field>

				<v-btn color="primary" large block @click="CreateBooking" :loading="creatingBooking">{{
					$t("createBooking")
					}}</v-btn>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
// import ClientAccountPicker from "@/components/Account/ClientAccountPicker";
import BookingTypePicker from "@/components/NutriYou/BookingTypePicker";
import DateTimePicker from "@/components/Shared/FormControl/DateTimePicker";
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";

export default {
	$_veeValidate: { validator: "TallyPatientFormDialog" },
	components: { BookingTypePicker, DateTimePicker },
	data() {
		return {
			bookingTypeId: null,
			bookingStatusId: null,
			duration: null,
			price: 0,
			bookingTime: null,

			creatingBooking: false,
		};
	},
	props: {
		value: { type: Boolean, required: true },
		PatientUserId: { type: String },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("CreateBookingDialog watch value");
			this.$log.debug(val);

			if (!val) this.CloseDialog();
		},
	},
	created() {
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		CloseDialog() {
			this.$emit("input", false);
		},

		onBookingTypeChanged() {
			if (this.bookingTypeId === BookingTypesEnum.FREE) {
				this.duration = 30;
				this.price = 0;
			} else if (this.bookingTypeId === BookingTypesEnum.NORMAL) {
				this.duration = 50;
				this.price = 49;
			} else if (this.bookingTypeId === BookingTypesEnum.MEALPLAN) {
				this.duration = 30;
				this.price = 49;
			}
		},

		async CreateBooking() {
			const dto = {
				PatientUserId: this.PatientUserId,
				NutritionistUserId: this.nutritionistUserId,
				BookingTypeId: this.bookingTypeId,
				// BookingStatusId: 1,
				// Duration: this.duration,
				// Price: this.price,
				BookingTime: this.bookingTime,
			};

			try {
				this.creatingBooking = true;
				const bookingId = await this.BookingsService.Post(null, dto);

				this.$emit("bookingCreated", bookingId);

				this.snackSuccess({ Text: this.$t("bookingCreated") });
			} catch (error) {
				this.snackError({ Text: this.$t("cannotCreateBooking") });
				this.$captureError(error);
				this.ClearForm();
			} finally {
				this.creatingBooking = false;
			}
		},

		ClearForm() {
			this.patientUserId = null;
			this.nutritionistUserId = null;
			this.bookingTypeId = null;
			this.bookingStatusId = null;
			this.duration = null;
			this.price = null;
			this.bookingTime = null;
		},
	},
};
</script>

<i18n>
{
	"it": {
		"dialogHeader": "Nuovo appuntamento",
		"createBooking": "Crea appuntamento",
		"bookingCreated": "Appuntamento Creato!",
		"cannotCreateBooking": "Impossibile creare l'appuntamento"
	}
}
</i18n>
