<template>
	<v-autocomplete
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', $event)"
		:loading="LoadingUsers"
		:label="$attrs.label || $t('sgp.client')"
		:items="clientAccounts"
		item-text="User.UserProfile.DisplayName"
		item-value="UserId"
		:search-input.sync="search"
		clearable
	></v-autocomplete>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			LoadingUsers: false,
			clientAccounts: [],
			search: null,
		};
	},
	props: {
		value: { type: String, default: null },
		RoleName: { type: String, default: null },
	},
	watch: {
		async search(val) {
			this.$log.debug("Watch search: " + val);
			val &&
				val !== this.value &&
				val.length > 0 &&
				(await this.LoadUserList(val));
		},

		value: {
			immediate: true,
			handler: async function (newVal) {
				if (!newVal) return;
				// Programmatic value set
				if (!this.clientAccounts.find((i) => i.UserId === newVal)) {
					const applicationUsersService = new CrudClient("UserManagement/Users");
					const res = await applicationUsersService.GetSelectedFields(
						newVal,
						"UserId, User.UserProfile.DisplayName"
					);

					this.search = res.Name;
				}
			},
		},
	},
	created() {
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
	},
	async mounted() {
		await this.LoadUserList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadUserList(val) {
			try {
				this.LoadingUsers = true;
				const res = await this.ApplicationUsersService.GetPaged({
					limit: 10,
					skip: 0,
					fields: "UserId, User.UserProfile.DisplayName",
					filter: `ApplicationUserRoles.Role.Name:${this.RoleName};User.UserProfile.DisplayName:like:${val}`,
					orderBy: "User.UserProfile.DisplayName",
				});
				this.clientAccounts = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingUsers = false;
			}
		},
	},
};
</script>
