<template>
	<v-banner
			v-if="message.attributes && message.attributes.MessageType === 'BookingCompleted'
				"
			tile
			class="messageBanner"
			:single-line="!$vuetify.breakpoint.xs"
			:class="{ mobile: $vuetify.breakpoint.xs }"
		>
			<p class="bannerBody">
				<!-- Nutriz -->
				<template v-if="view === 'sent'">
					<router-link
						:to="'/AreaNutritionists/Bookings/' + message.attributes.BookingId"
					>
						{{ formattedMessage }}
					</router-link>
				</template>

				<!-- Patient -->
				<template v-if="view === 'received'">
					<router-link :to="'/Patients/Agenda/' + message.attributes.BookingId">
						{{ formattedMessage }}
					</router-link>
				</template>
			</p>
		</v-banner>
</template>

<script>
import moment from "moment";
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";

export default {
	props: {
		message: {
			type: Object,
			required: true,
		},

		view: {
			type: String,
			required: true,

			validator: (value) => {
				return ["sent", "received"].indexOf(value) !== -1;
			},
		},
	},
	computed: {
		formattedMessage() {
			moment.locale("it");
			const m = moment(this.message.attributes.BookingTime);
			const formattedTime = m.format("L") + " " + m.format("LT");

			switch (this.message.attributes.BookingTypeId) {
				case BookingTypesEnum.FREE:
					return this.$t("freeVisit", { date: formattedTime });
				case BookingTypesEnum.NORMAL:
					return this.$t("normalVisit", { date: formattedTime });
				case BookingTypesEnum.MEALPLAN:
					return this.$t("mealPlan", { date: formattedTime });
			}
			return "";
		}
	}
};
</script>

<i18n>
{
	"it": {
		"normalVisit": "Visita Nutrizionale Completata!",
		"freeVisit": "Incontro Conoscitivo Completato!",
		"mealPlan": "Consegna e spiegazione del piano Completata!"
	}
}
</i18n>
