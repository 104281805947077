<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="800px"
		@keydown.esc="CloseDialog"
	>
		<div
			class="primary white--text px-5 py-3 d-flex align-center justify-space-between"
		>
			<span class="headline" style="word-break: normal">{{
				$t("dialogHeader")
			}}</span>
			<v-btn icon dark @click="CloseDialog">
				<v-icon>close</v-icon>
			</v-btn>
		</div>
		<v-progress-linear indeterminate v-if="LoadingData"></v-progress-linear>
		<TallyFormViewer v-else :TallyForm="tallyRequest" :AdminView="false"></TallyFormViewer>
	</v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import TallyFormViewer from "@/views/Admin/components/TallyFormViewer.vue";

export default {
	$_veeValidate: { validator: "TallyPatientFormDialog" },
	components: { TallyFormViewer },
	data() {
		return { LoadingData: false, tallyRequest: null };
	},
	props: {
		value: { type: Boolean, required: true },
		UserId: { type: String },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.CloseDialog();
		},

		UserId: async function (val) {
			this.$log.debug(val);
			await this.LoadTallyRequest(val);
		},
	},
	created() {
		this.TallyWebhookService = new CrudClient("TallyWebhook");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		CloseDialog() {
			this.$emit("input", false);
		},

		async LoadTallyRequest(userId) {
			if (!userId) return;
			try {
				this.LoadingData = true;
				const res = await this.TallyWebhookService.Get(null, "Patient/" + userId);

				this.tallyRequest = JSON.parse(res.Data);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"dialogHeader": "Risposte al questionario"
    }
}
</i18n>
