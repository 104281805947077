import { mapActions } from "vuex";

export default {
	data: () => ({
		metadataLoaded: false,
		metadata: {
			// filled dynamically
		},
	}),
	methods: {
		...mapActions(["snackMetadataError"]),
		async LoadMetadata(service, addApplicationId = true) {
			try {
				// TODO: add caching
				const data = await service.GetMetadata(addApplicationId);

				// add as reactive properties
				Object.keys(data).map(key => {
					this.$set(this.metadata, key, {
						ClientValidators: data[key].ClientValidators,
						MaxLength: data[key].MaxLength
					});

					// TODO: can be made generic and recursive
					if (data[key].NestedParameters) {
						const parentObject = data[key].NestedParameters;
						const parentMetadata = this.metadata[key];
						Object.keys(parentObject).map(innerKey => {
							this.$set(parentMetadata, innerKey, {
								ClientValidators: parentObject[innerKey].ClientValidators,
								MaxLength: parentObject[innerKey].MaxLength
							});
						});
					}
				});

				this.metadataLoaded = true;
			} catch (error) {
				this.$captureError(error);
				this.snackMetadataError();
			}
		},

		initializeFormDefaults(formScope, constFormFields) {
			const schema = {};

			// set standard fields
			constFormFields.forEach(fieldName => {
				schema[fieldName] = {
					key: fieldName,
					type: "v-text-field",
					label: this.$t(formScope + "." + fieldName),
					"data-vv-as": this.$t(formScope + "." + fieldName),

					"data-vv-scope": formScope,
					"data-vv-name": fieldName,
					"data-vv-delay": 300,
					counter: this.metadata[fieldName].MaxLength,
					"v-validate": this.metadata[fieldName].ClientValidators,
					"error-messages": this.errors.collect(formScope + "." + fieldName)
				};
			});

			return schema;
		}
	}
}
