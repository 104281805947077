<template>
	<div class="limitWidth">

		<h2 class="nutriyou-h3 text-center primary--text">Ciao {{ UserProfile.Name }}, eccoti nella tua Area Personale
		</h2>

		<div v-if="!initialSetupComplete">
			<v-container>
				<v-row>
					<v-col cols="12" md="6">
						<v-skeleton-loader class="mx-auto border" max-width="300"
							type="image, article"></v-skeleton-loader>
					</v-col>

					<v-col cols="12" md="6">
						<v-skeleton-loader class="mx-auto border" max-width="300"
							type="image, article"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div v-else>
			<v-card v-if="!PatientProfile.DefaultNutritionistUserId" class="ma-5 pa-5">
				<v-card-text>
					<p>
						Il nostro algoritmo sta cercando il nutrizionista <span class="font-weight-bold">su misura per
							te</span>.
					</p>
					<p>
						Di solito c'è bisogno di <span class="font-weight-bold">qualche ora</span> per cui ti chiediamo
						<span class="font-weight-bold">un attimo di pazienza</span> 🙂
					</p>
					<p>
						Appena trovato ti invieremo una email per avvisarti!
					</p>
				</v-card-text>
			</v-card>
			<div v-else>
				<v-container>
					<v-row>
						<v-col>
							<SetPasswordCard class="mx-auto"></SetPasswordCard>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<v-card color="nyGreenLight">
								<v-card-text>
									<div class="TitleFont text-center primary--text mb-5" style="font-size: 1.6rem;">
										{{ NutritionistProfile.ApplicationUser.DisplayName }}
									</div>
									<div class="d-flex justify-center mb-2">
										<v-img :src="NutritionistProfile.ImageFileUrl" max-width="120"></v-img>
									</div>
									<div class="text-center poppins-medium" style="font-size: 1.2rem;">
										{{ NutritionistProfile.ProfessionalTitle}}
									</div>
								</v-card-text>
								<v-card-actions>
									<v-container>
										<v-row>
											<v-col><v-btn color="nyGreenAlt darken-2" dark block large
													:to="'./Nutritionists/' + NutritionistProfile.ProfilePageUrl">
													<v-icon left>far fa-user</v-icon> Profilo</v-btn></v-col>
											<v-col><v-btn color="nyGreenAlt darken-2" dark block large
													:to="'/Chat?UserId=' + PatientProfile.DefaultNutritionistUserId">
													<v-icon left>fas fa-comment-alt-dots</v-icon>Chat</v-btn></v-col>
										</v-row>
									</v-container>
								</v-card-actions>
							</v-card>
						</v-col>
						<v-col cols="12" md="6">
							<v-card color="nyroseLight">

								<v-card-text>
									<div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1.2rem;">I
										prossimi appuntamenti</div>
									<skeleton-table v-if="LoadingData" :columns="3" :actions="0"></skeleton-table>
									<div v-else>
										<div v-if="!LoadingData && items.length === 0"
											class="text-center poppins-medium" style="font-size: 1rem;">
											{{ $t("NoBookings") }}
										</div>

										<div v-for="booking in items" :key="booking.BookingId">
											<MiniBookingCard :booking="booking">
											</MiniBookingCard>
											<v-divider class="my-3"></v-divider>
										</div>
									</div>
									<div class="text-right" v-if="!LoadingData && items.length > 0">
										<router-link to="/Patients/Agenda" class="text-body-1">
											<v-icon small color="primary">fas fa-list</v-icon>
											Tutti gli appuntamenti</router-link>
									</div>
								</v-card-text>
							</v-card>

						</v-col>
					</v-row>
				</v-container>
				<v-divider class="my-4"></v-divider>
				<h2 class="nutriyou-h3 mx-5 text-center primary--text">Dal nostro blog</h2>
				<StaticContentGrid class="my-5" :ContentIds="[1,2,3]"></StaticContentGrid>

			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SetPasswordCard from "./SetPassword";
import MiniBookingCard from "./MiniBookingCard";
import CrudClient from "@/services/CrudClient/";
import StaticContentGrid from "../../Public/Blog/StaticContentGrid.vue";

export default {
	components: {
		SetPasswordCard,
		MiniBookingCard,
		StaticContentGrid
	},
	data() {
		return {
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 3,
			filterByDate: 1,
			PatientProfile: null,
			NutritionistProfile: null,
			initialSetupComplete: false
		}
	},
	computed: {
		...mapGetters(["UserProfile"]),
	},
	created() {
		this.CurrentPatientProfileService = new CrudClient("CurrentPatient/Profile");
		this.BookingsService = new CrudClient("CurrentPatient/Bookings");
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
	},
	async mounted() {
		this.PatientProfile = await this.CurrentPatientProfileService.Get();
		if (this.PatientProfile.DefaultNutritionistUserId) {
			await this.LoadBookings();

			this.NutritionistProfile = await this.NutritionistsService.Get(this.PatientProfile.DefaultNutritionistUserId);
		}
		this.initialSetupComplete = true;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getDateString() {
			const today = new Date();
			// Attention: Janyary is 0
			return `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
		},
		async LoadBookings() {
			try {
				let filter = null;
				let orderBy;

				if (this.filterByDate === 1) {
					orderBy = "BookingTime:asc";
				} else if (this.filterByDate === 2) {
					orderBy = "BookingTime:desc";
				}

				if (this.filterByDate === 1 || this.filterByDate === 2) {
					if (filter) filter += ";";
					else filter = "";

					const formattedToday = this.getDateString();

					filter += `BookingTime:${this.filterByDate === 1 ? "gt" : "lt"}:${formattedToday}`;
				}

				filter += ";BookingStatusId:in:1,2,4"

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*",
					filter: filter,
					orderBy: orderBy,
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	}
}
</script>
<i18n>
	{
		"it":{
			"NoBookings": "Nessun appuntamento fissato."
		}
	}
</i18n>
<style lang="scss" scoped>
.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
</style>
