<template>
	<v-row>
		<v-col cols="12" md="10" class="offset-md-1">
			<StaticContentViewer
				v-if="ContentId"
				:ContentId="ContentId"
				:key="ContentId"
				:ShowBreadcrumbs="true"
			></StaticContentViewer>
		</v-col>
	</v-row>
</template>

<script>
// src\views\Admin\Content\components\StaticContent\Viewer.vue
import StaticContentViewer from "@/views/Admin/Content/components/StaticContent/Viewer";
import CrudClient from "@/services/CrudClient/";

export default {
	name: "StaticContentPage",
	components: { StaticContentViewer },
	data: () => ({
		ContentId: null
	}),
	computed: {
		FriendlyUrl() {
			return this.$route.params.FriendlyUrl;
		}
	},
	async mounted() {
		this.$log.info("StaticContent View Mounted");
		const StaticContentService = new CrudClient("StaticContent");

		const res = await StaticContentService.GetSelectedFields("FriendlyUrl/" + this.FriendlyUrl, "ContentId");

		this.ContentId = res.ContentId;
	},
};
</script>
