<template>
	<v-card
		flat
		class="sendFileCard pa-2"
		:class="{
			'blue-grey lighten-4': message.author === userId,
			'green lighten-4': message.author !== userId,
			'text-left': view === 'received',
			'text-right': view !== 'received',
			'widget-right': view !== 'received',
			'with-preview': message.attributes.FilePreviewUrl,
		}"
	>
		<v-card-text class="font-weight-medium pa-0">
			<div class="mb-1">
				{{
					view === "received" ? $t("common.fileReceived") : $t("common.fileSent")
				}}
			</div>
			<v-img
				class="mb-2"
				aspect-ratio="1.2"
				:src="message.attributes.FilePreviewUrl"
				v-if="message.attributes.FilePreviewUrl"
			>
				<template v-slot:placeholder>
					<v-row class="fill-height ma-0" align="center" justify="center">
						<v-progress-circular
							indeterminate
							color="grey lighten-5"
						></v-progress-circular>
					</v-row>
				</template>
			</v-img>
			<div>
				<v-icon
					class="mr-3"
					size="18px"
					:color="message.author === userId ? 'blue lighten-1' : 'green'"
					>fas fa-paperclip</v-icon
				>
				<a
					:href="message.attributes.FileUrl"
					:class="{
						'blue--text text--darken-2': message.author === userId,
						'green--text text--darken-3': message.author !== userId,
					}"
					target="_blank"
					>{{ message.attributes.FileDisplayName }}</a
				>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	props: {
		userId: String,
		message: {
			type: Object,
			required: true,
		},
		view: {
			type: String,
			required: true,
			validator: (value) => {
				return ["sent", "received"].indexOf(value) !== -1;
			},
		},
	},
	data: () => ({}),
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.sendFileCard {
	border-radius: 10px !important;
	display: flex;
	align-items: flex-start;
	@media #{map-get($display-breakpoints, 'xs-only')} {
		&:hover {
			.messageActions {
				display: initial;
			}
		}
	}
	&.with-preview {
		min-width: 280px !important;
	}
}
</style>
