<template>
  <div>
  <v-list style="padding-top: 0">
    <BrowserNotificationsConsent></BrowserNotificationsConsent>
  </v-list>
    <v-card color="nyGreenLight" class="mx-2" v-if="initialSetupComplete">
      <v-card-text>
        <div class="TitleFont text-center primary--text mb-5" style="font-size: 1.6rem;">
          {{ NutritionistProfile.ApplicationUser.DisplayName }}
        </div>
        <div class="d-flex justify-center mb-2">
          <v-img :src="NutritionistProfile.ImageFileUrl" max-width="120"></v-img>
        </div>
        <div class="text-center poppins-medium" style="font-size: 1.2rem;">
          {{ NutritionistProfile.ProfessionalTitle}}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col><v-btn color="nyGreenAlt darken-2" dark block large
                          :to="'./Nutritionists/' + NutritionistProfile.ProfilePageUrl">
              <v-icon left>far fa-user</v-icon> Profilo</v-btn></v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import BrowserNotificationsConsent from "@/components/Chat/BrowserNotificationsConsent.vue";
import CrudClient from "@/services/CrudClient";
import { mapGetters } from "vuex";

export default {
	name: "PatientConnectToNutritionist",
	components: { BrowserNotificationsConsent },
	data() {
		return {
			PatientProfile: null,
			NutritionistProfile: null,
			initialSetupComplete: false
		}
	},
	computed: {
		...mapGetters(["CombinedConversationView"]),
	},
	created() {
		this.CurrentPatientProfileService = new CrudClient("CurrentPatient/Profile");
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
	},
	async mounted() {
		this.PatientProfile = await this.CurrentPatientProfileService.Get();
		if (this.PatientProfile.DefaultNutritionistUserId) {
			this.NutritionistProfile = await this.NutritionistsService.Get(this.PatientProfile.DefaultNutritionistUserId);
		}
		this.initialSetupComplete = true;
	},
}
</script>
<style scoped lang="scss">

</style>
