var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-wrapper"},[_c('div',{staticClass:"rounded",class:{ 'elevation-5': !_vm.$vuetify.breakpoint.xs }},[_c('v-toolbar',{attrs:{"color":"blue-grey lighten-4 "}},[(_vm.isUserInRole('Nutritionist') && _vm.$vuetify.breakpoint.xs && _vm.currentConversationUserId)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.ResetCurrentConversationUserId}},[_c('v-icon',[_vm._v("fas fa-arrow-circle-left")])],1):_vm._e(),(_vm.isUserInRole('Nutritionist'))?_c('v-toolbar-title',[(_vm.$vuetify.breakpoint.xs && _vm.CurrentConversationView)?_c('span',[_vm._v(_vm._s(_vm.CurrentConversationView.DisplayName)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("pageTitle")))])]):_vm._e(),(_vm.isUserInRole('Patient') && _vm.CurrentConversationView)?_c('span',[(_vm.$vuetify.breakpoint.xs)?_c('span',[_c('user-avatar-fullname',{staticStyle:{"display":"inline-block"},attrs:{"showDisplayName":true,"profile":_vm.CurrentConversationView,"size":40}}),_c('v-icon',{staticStyle:{"display":"inline"},attrs:{"size":"14","color":_vm.CurrentConversationView.isOnline ? 'green' : 'grey'}},[_vm._v(" fas fa-circle")])],1):_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("pageTitle")))])],1):_vm._e(),_c('v-spacer'),(_vm.isUserInRole('Nutritionist') && _vm.$vuetify.breakpoint.xs && _vm.currentConversationUserId)?_c('span',[_c('NutritionistListItemActions',{attrs:{"Conversation":_vm.CurrentConversationView},on:{"conversationArchived":function($event){_vm.CurrentConversationView.IsArchived = 1},"conversationUnarchived":function($event){_vm.CurrentConversationView.IsArchived = 0}}})],1):_vm._e()],1),_c('v-container',{staticClass:"chat-container",attrs:{"fluid":""}},[_c('v-row',[(
							(!_vm.currentConversationUserId && _vm.$vuetify.breakpoint.xs) ||
							!_vm.$vuetify.breakpoint.xs
						)?_c('v-col',{staticClass:"grey lighten-5 pa-0 chatList",attrs:{"cols":"12","sm":"4"}},[(_vm.isUserInRole('Nutritionist'))?_c('NutritionistConversationsList'):_vm._e(),(_vm.isUserInRole('Patient'))?_c('PatientConnectToNutritionist'):_vm._e()],1):_vm._e(),(
							(_vm.currentConversationUserId && _vm.$vuetify.breakpoint.xs) ||
							!_vm.$vuetify.breakpoint.xs
						)?_c('v-col',{staticClass:"orange lighten-5 pa-0",attrs:{"cols":"12","sm":"8"}},[(_vm.currentConversationUserId)?_c('ChatWindow',{key:_vm.currentConversationUserId}):[_c('v-row',{staticClass:"flex-column align-center justify-center",staticStyle:{"height":"100%"},attrs:{"ma-5":""}},[_c('v-icon',{staticClass:"shadow ma-3",attrs:{"size":"160","color":"secondary lighten-2"}},[_vm._v("fas fa-comments-alt")])],1)]],2):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }