import CrudClient from "@/services/CrudClient/";
import Vue from "vue"
import logger from "@/utilities/logger"
import i18n from "@/i18n.js"

import { Client } from "@twilio/conversations";

async function _playSound(sound) {
	if (sound) {
		try {
			const audio = new Audio(sound);
			await audio.play();
			navigator.vibrate(200);
		} catch (error) {
			logger.$log.debug("error");
		}
	}
}

async function _getUnconsumedMessageCount(context, conversation) {
	const unconsumedMessagesCount = await conversation.getUnreadMessagesCount();

	logger.$log.debug(unconsumedMessagesCount);
	if (unconsumedMessagesCount !== null) { return unconsumedMessagesCount; }

	return await conversation.getMessagesCount();
}

async function _initializeConversation(context, conversationSid) {
	const conversation = await context.state.chatClient.getConversationBySid(conversationSid);

	logger.$log.debug("_initializeConversation:" + conversationSid);

	const index = context.getters.CombinedConversationView
		.findIndex(c => c.ConversationSid === conversation.ConversationSid);
	if (index !== -1) {
		return;
	}

	const userId = conversation.attributes.Members.filter(m => m.toLowerCase() !== context.getters.UserProfile.Id.toLowerCase())[0];
	const newMessages = await _getUnconsumedMessageCount(context, conversation);

	const participants = await conversation.getParticipants();

	// subscribe to other user
	const filteredParticipants = participants
		.filter(m => m.identity.toLowerCase() !== context.getters.UserProfile.Id.toLowerCase());

	const user = await filteredParticipants[0].getUser();

	user.on("updated", async ({
		user,
		updateReasons
	}) => {
		logger.$log.debug("user updated:");
		if (updateReasons[0] === "reachabilityOnline") {
			const foundConversation = context.getters.CombinedConversationView
				.find(c => c.UserId.toLowerCase() === user.identity.toLowerCase());

			if (foundConversation) {
				Vue.set(foundConversation, "isOnline", user.isOnline);
			} else {
				logger.$log.error("conversation not found");
			}
		}
	})

	return {
		conversation: conversation,
		sid: conversation.sid,
		userId: userId,
		newMessageCount: newMessages,
		isOnline: user.state.online,
		date: (conversation.lastMessage) ? conversation.lastMessage.dateCreated : conversation.dateCreated
	};
}

async function _showNotification(context, message) {
	if (!("Notification" in window)) {
		logger.$log.console.warn("Notifications not enabled");
		return;
	}

	if (Notification.permission === "granted") {
		await context.dispatch("LoadUserPublicProfile", message.author);
		const profile = context.getters.UserPublicProfiles[message.author];
		let displayText = "";

		if (!message.attributes.MessageType) { displayText = message.body }

		switch (message.attributes.MessageType) {
			case "Deleted":
				displayText = i18n.t("common.chatNotifications.deleted");
				break;

			case "SendFile":
				displayText = i18n.t("common.fileReceived");
				break;
		}

		if (!displayText) { return; }

		const notification = new Notification(profile.DisplayName, {
			body: displayText,
			silent: true,
			icon: profile.ImageFilePath ? profile.ImageFilePath[200] : null
		});

		notification.onclick = async (event) => {
			logger.$log.debug("notification clicked");
			event.preventDefault();
			parent.focus();
			window.focus();
			event.target.close();

			if (window.location.pathname.startsWith("/Chat")) {
				await context.dispatch("SetCurrentConversationUserId", message.author)
			} else {
				// TODO: using ruoter in module creates navigation problems
				// find another way to redirect
				// router.push("/Chat?UserId=" + message.author);
				context.state.chatNotificationRedirectUrl = "/Chat?UserId=" + message.author;
			}
		};
	}
}

async function _onConversationAdded(context, conversation) {
	logger.$log.error("_onConversationAdded: " + conversation.sid);

	const participants = await conversation.getParticipants();

	const participant = participants
		.find(m => m.identity.toLowerCase() !== context.getters.UserProfile.Id.toLowerCase());

	const userId = participant.identity;

	const addedConversationView = context.getters.CombinedConversationView.find(c => c.UserId === userId);

	if (!addedConversationView) {
		logger.$log.warn("conversation not found");
		await context.dispatch("LoadConversations");
	} else {
		const user = await participant.getUser();

		Vue.set(addedConversationView, "TwilioConversation", await _initializeConversation(context, conversation.sid));
		Vue.set(addedConversationView, "ConversationSid", conversation.sid);
		Vue.set(addedConversationView, "isOnline", user.state.online);

		logger.$log.info("added conversation updated");
	}
}
async function _onConversationRemoved(context, conversation) {
	logger.$log.debug("_onConversationRemoved: " + conversation.sid);

	const removedConversationView = context.getters.CombinedConversationView
		.find(c => c.ConversationSid === conversation.sid);

	if (!removedConversationView) {
		logger.$log.error("removed conversaiton not found");
		return;
	}

	const isCurrentConversation = context.getters
		.currentConversationUserId?.toLowerCase() === removedConversationView?.UserId.toLowerCase();

	// if current conversation is active now, reset current conversation
	if (isCurrentConversation) {
		logger.$log.info("removed conversation was active: " + conversation.sid);
		context.commit("SET_CURRENT_CONVERSATION_USER_ID", null);
		await context.dispatch("ClearConversationSelectedFiles", conversation.sid);
	}
	Vue.set(removedConversationView, "isOnline", false);
	Vue.set(removedConversationView, "TwilioConversation", null);
	Vue.set(removedConversationView, "ConversationSid", null);

	// await context.dispatch("LoadConversations");
}

export default {
	state: {
		chatClient: null,
		twilioAccessToken: null,
		combinedConversationView: [],
		UserPublicProfiles: {},
		isLoadingConversationView: false,
		isChatWindowActive: false,
		conversationUploadQueue: [],
		conversationSelectedFiles: [],
		currentConversationUserId: null,
		chatNotificationRedirectUrl: null,
		twilioRefreshAccessTokenInterval: null
	},
	mutations: {
		ADD_USE_PUBLIC_PROFILE: (state, profile) => {
			Vue.set(state.UserPublicProfiles, profile.Id, profile);
		},

		ADD_CONVERSATION_UPLOAD_QUEUE: (state, {
			conversationId,
			uploadStatus
		}) => {
			if (state.conversationUploadQueue[conversationId] === undefined) {
				Vue.set(state.conversationUploadQueue, conversationId, []);
			}

			state.conversationUploadQueue[conversationId].push(uploadStatus);
		},

		SET_CONVERSATION_UPLOAD_QUEUE_PROGRESS: (state, {
			conversationId,
			uploadItemIndex,
			progress
		}) => {
			Vue.set(state.conversationUploadQueue[conversationId][uploadItemIndex], "progress", progress);
		},

		SET_CONVERSATION_UPLOAD_QUEUE_COMPLETE: (state, {
			conversationId,
			uploadItemIndex,
			completed
		}) => {
			Vue.set(state.conversationUploadQueue[conversationId][uploadItemIndex], "completed", completed);
		},

		INIT_CONVERSATION_SELECTED_FILES: (state, conversationId) => {
			logger.$log.debug("INIT_CONVERSATION_SELECTED_FILES");

			if (state.conversationSelectedFiles[conversationId] === undefined) {
				Vue.set(state.conversationSelectedFiles, conversationId, []);
			}
		},

		ADD_CONVERSATION_SELECTED_FILES: (state, {
			conversationId,
			file
		}) => {
			logger.$log.debug("ADD_CONVERSATION_SELECTED_FILES");

			if (state.conversationSelectedFiles[conversationId] === undefined) {
				logger.$log.error(`SelectedFiles not initialized. ConversationId:${conversationId}`);
			}

			state.conversationSelectedFiles[conversationId].push(file);
		},

		CLEAR_CONVERSATION_SELECTED_FILES: (state, conversationId) => {
			logger.$log.debug("CLEAR_CONVERSATION_SELECTED_FILES:" + conversationId);

			if (state.conversationSelectedFiles[conversationId] === undefined) {
				logger.$log.error(`SelectedFiles not initialized. ConversationId:${conversationId}`);
			}
			state.conversationSelectedFiles[conversationId] = [];
		},

		SET_TWILIO_ACCESS_TOKEN: (state, token) => {
			state.twilioAccessToken = token;
		},

		SET_TWILIO_CHAT_CLIENT: (state, client) => {
			state.chatClient = client;
		},

		SET_COMBINED_CHAT_CONVERSATIONS: (state, conversations) => {
			state.combinedConversationView = conversations;
		},

		SET_LOADING_CONVERSATION_VIEW: (state, isLoadingConversationView) => {
			// logger.$log.debug("SET_LOADING_CONVERSATION_VIEW : " + isLoadingConversationView);
			state.isLoadingConversationView = isLoadingConversationView;
		},

		SET_CHAT_WINDOW_ACTIVE: (state, windowState) => {
			state.isChatWindowActive = windowState;
		},

		SET_CURRENT_CONVERSATION_USER_ID: (state, userId) => {
			state.currentConversationUserId = userId;
		},

		SET_CHAT_NOTIFICATION_REDIRECT_URL: (state, url) => {
			state.chatNotificationRedirectUrl = url;
		},

		SET_TWILIO_REFRESH_ACCESS_TOKEN_INTERVAL: (state, interval) => {
			state.twilioRefreshAccessTokenInterval = interval;
		},

		RESET_TWILIO_CHAT_CLIENT: (state) => {
			state.chatClient = null;
			state.twilioAccessToken = null;
			state.combinedConversationView = [];
			state.UserPublicProfiles = {};
			state.isLoadingConversationView = false;
			state.isChatWindowActive = false;
			state.conversationUploadQueue = [];
			state.currentConversationUserId = null;
			state.chatNotificationRedirectUrl = null;
			state.twilioRefreshAccessTokenInterval = null;
		}
	},

	actions: {
		AddConversationUploadQueue(context, {
			conversationId,
			uploadStatus
		}) {
			context.commit("ADD_CONVERSATION_UPLOAD_QUEUE", {
				conversationId,
				uploadStatus
			});
		},

		SetConversationUploadQueueProgress(context, {
			conversationId,
			uploadItemIndex,
			progress
		}) {
			context.commit("SET_CONVERSATION_UPLOAD_QUEUE_PROGRESS", {
				conversationId,
				uploadItemIndex,
				progress
			});
		},

		SetConversationUploadQueueComplete(context, {
			conversationId,
			uploadItemIndex,
			completed
		}) {
			context.commit("SET_CONVERSATION_UPLOAD_QUEUE_COMPLETE", {
				conversationId,
				uploadItemIndex,
				completed
			});
		},

		InitConversationSelectedFiles(context,
			conversationId) {
			context.commit("INIT_CONVERSATION_SELECTED_FILES", conversationId);
		},

		AddConversationSelectedFiles(context, {
			conversationId,
			file
		}) {
			context.commit("ADD_CONVERSATION_SELECTED_FILES", {
				conversationId,
				file
			});
		},

		ClearConversationSelectedFiles(context, conversationId) {
			context.commit("CLEAR_CONVERSATION_SELECTED_FILES", conversationId);
		},

		SetChatWindowState(context, windowState) {
			context.commit("SET_CHAT_WINDOW_ACTIVE", windowState);
		},

		async SetCurrentConversationUserId(context, userId) {
			logger.$log.debug("SetCurrentConversationUserId:" + userId);

			if (context.getters.currentConversationUserId?.toLowerCase() === userId.toLowerCase()) { return; }

			context.commit("SET_CURRENT_CONVERSATION_USER_ID", userId);

			// check if it has the twilio object, otherwise create the conversation and add to the
			const combinedConversationView = context.getters.CombinedConversationView.find(c => c.UserId.toLowerCase() === userId.toLowerCase());

			if (combinedConversationView && !combinedConversationView.TwilioConversation) {
				Vue.set(combinedConversationView, "CreatingTwilioConversation", true);
				const createConversationResult = await context.dispatch("createConversation", userId);

				logger.$log.debug("createConversation completed");
				logger.$log.debug(createConversationResult);

				Vue.set(combinedConversationView, "TwilioConversation", await _initializeConversation(context, createConversationResult.Sid));
				Vue.set(combinedConversationView, "ConversationSid", createConversationResult.Sid);

				await context.dispatch("InitConversationSelectedFiles", createConversationResult.Sid);

				Vue.set(combinedConversationView, "CreatingTwilioConversation", false);
			}
		},

		ResetCurrentConversationUserId(context) {
			context.commit("SET_CURRENT_CONVERSATION_USER_ID", null);
		},

		async CreateChatClient(context) {
			logger.$log.info("CreateChatClient");
			// if no user, do not create
			if (!context.getters.isUserAuthenticated) {
				logger.$log.debug("user is not signed in, chat client is not created");
				return;
			}

			// TODO: chech if chatClient exists for the current user
			if (context.getters.TwilioChatClient) {
				logger.$log.debug("user has already chat client");
				return;
			}

			try {
				await context.dispatch("fetchAccessToken");

				logger.$log.debug(context.state.twilioAccessToken);

				const chatClient = new Client(context.state.twilioAccessToken);

				chatClient.on("stateChanged", (state) => {
					if (state === "initialized") {
						logger.$log.info("stateChanged ->" + state);
					}
				});

				const interval = setInterval(async () => {
					await context.dispatch("fetchAccessToken");
					await chatClient.updateToken(context.state.twilioAccessToken);
				}, 1000 * 60 * 30);

				context.commit("SET_TWILIO_REFRESH_ACCESS_TOKEN_INTERVAL", interval);

				// chatClient.on("connectionStateChanged", state => {
				// 	logger.$log.info("connectionStateChanged ->" + state);
				// });

				// chatClient.on("connectionError", error => {
				// 	logger.$log.error("connectionError");
				// 	logger.$log.error(error);
				// });

				context.commit("SET_TWILIO_CHAT_CLIENT", chatClient);

				logger.$log.debug("LoadConversations from create chat dialogs");
				await context.dispatch("LoadConversations");

				logger.$log.debug("Setting event handlers");
				// chat events
				chatClient.on("tokenAboutToExpire", async () => {
					try {
						logger.$log.info("Refreshing chat access token ");
						await context.dispatch("fetchAccessToken");
						await chatClient.updateToken(context.state.twilioAccessToken);
					} catch (err) {
						logger.$log.error(err);
					}
				});

				chatClient.on("messageAdded", async message => {
					try {
						logger.$log.info("Chat client - message added ");
						logger.$log.info(message);

						if (message.author.toLowerCase() !== context.getters.UserProfile.Id.toLowerCase()) {
							await _playSound(
								"/audio/newmessage.mp3"
							);

							// TODO: check vuex -> chat window open
							// show browser notification
							await _showNotification(context, message);
						}
						// update last activity time of the conversation and reorder
						context.getters.CombinedConversationView
							.find(c => c.ConversationSid === message.conversation.sid).LastMessageDate = message.dateCreated
						await context.dispatch("SortConversationsByDate");
					} catch (err) {
						logger.$log.error(err);
					}
				});

				chatClient.on("conversationAdded", async conversation => {
					// conversation added is called when the chat client is initialized
					// this check prevents double load conversations

					await _onConversationAdded(context, conversation);
				});
				// A conversation is no longer visible to the Client
				chatClient.on("conversationRemoved", async c => {
					await _onConversationRemoved(context, c)
				});

				// A conversation's attributes or metadata have changed.
				chatClient.on("conversationUpdated", async (data) => {
					try {
						logger.$log.debug("conversationUpdated: " + data.conversation.sid + " " + data.updateReasons[0]);

						if (data.updateReasons[0] === "lastMessage" || data.updateReasons[0] === "lastReadMessageIndex") {
							const combinedConversationView = context.getters.CombinedConversationView
								.find(v => v.ConversationSid === data.conversation.sid);

							if (combinedConversationView) {
								setTimeout(async () => {
									const newMessageCount = await _getUnconsumedMessageCount(context, data.conversation);
									// Vue.set(combinedConversationView.TwilioConversation, "newMessageCount", newMessageCount)
									combinedConversationView.TwilioConversation.newMessageCount = newMessageCount;
								}, 400);
							}
						} else {
							logger.$log.debug("LoadConversations required after conversationUpdated");

							await context.dispatch("LoadConversations");
						}
					} catch (err) {
						logger.$log.error(err);
					}
				});
			} catch (error) {
				logger.$log.error(error, "CreateChatClient");
			}
		},

		async LoadConversations(context) {
			logger.$log.info("Load Conversations called");

			if (!context.getters.TwilioChatClient) {
				context.commit("SET_COMBINED_CHAT_CONVERSATIONS", null);
				return;
			}

			context.commit("SET_LOADING_CONVERSATION_VIEW", true);

			const conversationsListView = await context.dispatch("fetchUserConversations");
			logger.$log.debug(conversationsListView);

			for (const conversation of conversationsListView) {
				conversation.LastMessageDate = new Date(conversation.LastMessageDate);
				logger.$log.debug("Processing Conversation (in LoadConversations): " + conversation.ConversationSid);
				if (conversation.ConversationSid) {
					conversation.TwilioConversation = await _initializeConversation(context, conversation.ConversationSid);
					conversation.isOnline = conversation.TwilioConversation.isOnline;
				}
			}

			context.commit("SET_COMBINED_CHAT_CONVERSATIONS", conversationsListView);

			context.commit("SET_LOADING_CONVERSATION_VIEW", false);
		},

		SortConversationsByDate(context) {
			logger.$log.info("SortConversationsByDate");
			logger.$log.debug(context.getters.CombinedConversationView.map(c => c.LastMessageDate));

			if (context.getters.CombinedConversationView) {
				context.getters.CombinedConversationView.sort(

					(a, b) => {
						return (a.LastMessageDate < b.LastMessageDate) ? 1 : -1;
					}
				)
			}
		},

		async ShutdownChatClient(context) {
			if (context.state.chatClient) {
				logger.$log.debug("shutting down chat client");

				clearInterval(context.state.twilioRefreshAccessTokenInterval);

				await context.state.chatClient.shutdown();
				context.commit("RESET_TWILIO_CHAT_CLIENT");
			}
		},

		async fetchAccessToken(context) {
			try {
				const chatService = new CrudClient("Chat");

				const res = await chatService.GetCustom("CreateToken/browser");

				context.commit("SET_TWILIO_ACCESS_TOKEN", res.token);
			} catch (err) {
				logger.$log.error(err);
			}
		},

		async fetchUserConversations(context) {
			try {
				const chatService = new CrudClient("Chat");

				if (context.getters.isUserInRole("Patient")) {
					return await chatService.Get(null, "PatientConversations");
				}

				if (context.getters.isUserInRole("Nutritionist")) {
					return await chatService.Get(null, "NutritionistConversations");
				}
			} catch (err) {
				logger.$log.error(err);
			}
		},

		async createConversation(context, toUserId) {
			const chatService = new CrudClient("Chat");
			return await chatService.GetCustom(`DirectConversation/${toUserId}`);
		}
	},

	getters: {
		CombinedConversationView: (state) => {
			return state.combinedConversationView;
		},

		TwilioChatClient: (state) => {
			return state.chatClient
		},

		TotalNewMessages: (state) => {
			if (!state.combinedConversationView) { return 0; }

			return state.combinedConversationView
				.filter(c => !!c.TwilioConversation)
				.map(c => c.TwilioConversation.newMessageCount)
				.reduce((prev, next) => prev + next, 0);
		},

		isLoadingConversationView: (state) => {
			return state.isLoadingConversationView;
		},

		isChatWindowActive: (state) => {
			return state.isChatWindowActive;
		},

		currentConversationUserId: (state) => {
			return state.currentConversationUserId;
		},

		conversationUploadQueue: (state) => {
			return state.conversationUploadQueue;
		},

		chatNotificationRedirectUrl: (state) => {
			return state.chatNotificationRedirectUrl
		},

		conversationSelectedFiles: (state) => {
			return state.conversationSelectedFiles;
		}
	}
}
