<template>
	<v-navigation-drawer app :right="right" v-model="showDrawer">
		<v-card
			color="secondary"
			flat
			tile
			dark
			class="pa-3"
			v-if="isUserAuthenticated"
		>
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12">
						<router-link class="profileLink" to="/Account/Profile">
							<user-avatar-fullname
								:size="44"
								:showDisplayName="true"
								textClass="white-text ml-3"
								:profile="UserProfile"
							></user-avatar-fullname>
						</router-link>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-list dense class="pt-0 text-capitalize">
			<v-list-item to="/" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-home</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Home</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item v-if="!isUserAuthenticated" to="/Login" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-sign-in-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("common.login") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<template v-if="isUserInRoles(['Patient'])"
				><v-subheader>{{ $t("PatientSection") }}</v-subheader>

				<v-list-item to="/Chat" color="primary">
					<v-list-item-action>
						<v-badge color="red" v-if="TotalNewMessages > 0">
							<template v-slot:badge>
								<span>{{ TotalNewMessages }}</span>
							</template>
							<v-icon>fas fa-fw fa-comment-alt-dots</v-icon>
						</v-badge>
						<v-icon v-else>fas fa-fw fa-comment-alt-dots</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ $t("common.chat") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item to="/Patients/Agenda" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-calendar-alt</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ $t("nutriyou.yourbookings") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item to="/Account/Profile" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-user</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ $t("profile") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>

			<template v-if="isUserInRoles(['Nutritionist'])"
				><v-subheader>{{ $t("NutritionistSection") }}</v-subheader>
				<v-list-item to="/AreaNutritionists" color="primary">
					<v-list-item-action>
						<v-icon>far fa-fw fa-clipboard-prescription</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{
							$t("nutriyou.areaNutritionists")
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/Chat" color="primary">
					<v-list-item-action>
						<v-badge color="red" v-if="TotalNewMessages > 0">
							<template v-slot:badge>
								<span>{{ TotalNewMessages }}</span>
							</template>
							<v-icon>fas fa-fw fa-comment-alt-dots</v-icon>
						</v-badge>
						<v-icon v-else>fas fa-fw fa-comment-alt-dots</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ $t("common.chat") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
						<v-list-item to="/Account/Profile" color="primary">
						<v-list-item-action>
							<v-icon>fas fa-fw fa-user</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ $t("profile") }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
			</template>
			<template v-if="isUserInRoles(['BusinessAdmin', 'SystemAdmin'])">
				<v-subheader>{{ $t("AdminSection") }}</v-subheader>

				<v-list-item to="/Admin" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-calendar-star</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ $t("nutriyou.areaAdmin") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-list-item v-if="isUserInRole('UserManager')" to="/Users" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-users</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("UserManagement") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				v-if="isUserInRole('SystemAdmin')"
				to="/SystemSettings"
				color="primary"
			>
				<v-list-item-action>
					<v-icon>fas fa-fw fa-cogs</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("SystemAdmin") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset></v-divider>

			<v-list-item to="/Nutritionists" color="primary" v-if="!isUserInRoles(['Patient'])"
			>
				<v-list-item-action>
					<v-icon>fas fa-fw fa-apple-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>I nutrizionisti di Nutriyou</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item to="/FAQ" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-question-circle</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>FAQ</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item to="/ContactUs" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-envelope</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("common.contactUs") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset v-if="isUserAuthenticated"></v-divider>
			<v-list-item v-if="isUserAuthenticated" @click="DoSignout">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-sign-out-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("common.logout") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";

export default {
	name: "side-bar",
	components: { UserAvatarFullname },
	data() {
		return {
			menuLinks: [{ title: "Home", icon: "fas fa-fw fa-home", to: "/" }],
			right: false,
			showDrawer: !this.$vuetify.breakpoint.smAndDown,
		};
	},
	computed: {
		...mapGetters([
			"TotalNewMessages",
			"UserProfile",
			"isUserAuthenticated",
			"isUserInRole",
			"isUserInRoles",
		]),
	},
	methods: {
		...mapActions(["SignOut"]),

		ToggleDrawer() {
			this.showDrawer = !this.showDrawer;
		},

		DoSignout() {
			this.SignOut();
			this.$router.push("/Login");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

a.profileLink {
	color: white;
	&:hover {
		color: $primary;
	}
	& > div {
		display: flex;
		align-items: center;
	}
}
</style>

<i18n>
{
	"it": {
		"AdminSection": "Area Admin",
		"SystemAdmin": "System Admin",
		"UserManagement": "Gestione Utenti",
		"support": "Assistenza",
		"PatientSection": "La Tua Area Personale",
		"NutritionistSection": "Area Nutrizionista",
		"profile": "I tuoi Dati"
	}
}
</i18n>
