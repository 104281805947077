<template>
	<v-card-actions class="px-4 pb-4 pt-0">
		<v-spacer></v-spacer>
		<v-btn
			v-bind="closeButtonProps"
			:color="
				closeButtonProps && closeButtonProps.color
					? closeButtonProps.color
					: 'primary'
			"
			text
			@click="OnCloseClicked"
			v-if="showCancelButton && !loading"
		>
			<slot name="btnClose">{{ $t("common.close") }}</slot>
		</v-btn>

		<v-btn
			@click="OnAddOrSaveClicked"
			:color="
				addButtonProps && addButtonProps.color ? addButtonProps.color : 'primary'
			"
			:loading="loading"
			:disabled="disabled"
			v-if="mode == 1"
			v-bind="addButtonProps"
		>
			<slot name="btnAdd">
				{{ $t("common.add") }}
				<v-icon right dark>fas fa-plus</v-icon>
			</slot>
		</v-btn>

		<v-btn
			v-bind="saveButtonProps"
			@click="OnAddOrSaveClicked"
			:color="
				saveButtonProps && saveButtonProps.color ? saveButtonProps.color : 'primary'
			"
			:loading="loading"
			:disabled="disabled"
			v-if="mode == 2"
		>
			<slot name="btnSave">
				{{ $t("common.save") }}
				<v-icon right dark>fas fa-save</v-icon>
			</slot>
		</v-btn>
	</v-card-actions>
</template>
<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		showCancelButton: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: Number,
			default: 1,
		},

		addButtonProps: {
			type: Object,
		},
		saveButtonProps: {
			type: Object,
		},
		closeButtonProps: {
			type: Object,
		},
	},
	methods: {
		OnAddOrSaveClicked() {
			this.$emit("addOrSaveClicked");
		},

		OnCloseClicked() {
			this.$emit("closeClicked");
		},
	},
};
</script>
