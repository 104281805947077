<template>
  <v-list style="padding-top: 0">
    <BrowserNotificationsConsent></BrowserNotificationsConsent>

    <skeleton-table
      v-if="isLoadingConversationView || creatingConversation"
      avatar
      :columns="1"
      :actions="0"
    ></skeleton-table>
    <template v-else>

      <v-list-item dense >
        <v-switch v-model="showAllPatients" label="Tutti i pazienti" ></v-switch>
      </v-list-item>
      <v-list-item v-for="conv in ListedConversations"
                   :key="conv.UserId"
                   class="conversationItem pa-0 pr-1"
                   :class="{ active: conv.UserId === currentConversationUserId }"
                   @click="SetCurrentConversationUserId(conv.UserId)"
      >
        <v-list-item-content
          class="pa-0 chatConversation"
        >
          <div
            class="conversationName pa-3 pr-0"
          >
            <div>
              <v-badge
                :color="conv.isOnline ? 'green' : 'grey'"
                class="onlineStatus"
                bottom
                overlap
              >
                <user-avatar-fullname
                  :showDisplayName="false"
                  :profile="conv"
                  :size="50"
                ></user-avatar-fullname>
                <template v-slot:badge>&nbsp;</template>
              </v-badge>
            </div>
            <div class="font-weight-medium ml-3"
            :class="{'font-italic': !conv.TwilioConversation}">
              {{ conv.DisplayName }}
              <span class="grey--text" style="font-size:12px">{{ conv.EmailIdentifier }}</span>
            </div>
            <v-icon v-if="conv.IsArchived" small class="ml-2"
                    color="grey darken-1">fas fa-inbox-in</v-icon>
          </div>
        </v-list-item-content>
        <v-list-item-action>
          <v-badge
            v-if="conv.TwilioConversation?.newMessageCount > 0"
            inline
            color="error"
            class="mr-3"
            :content="conv.TwilioConversation?.newMessageCount > 99 ? '+99' : conv.TwilioConversation?.newMessageCount"
          ></v-badge>

          <NutritionistListItemActions
            @conversationArchived="conv.IsArchived = 1"
            @conversationUnarchived="conv.IsArchived = 0"
            v-if="conv.UserId === currentConversationUserId"
            :Conversation="conv">
          </NutritionistListItemActions>
        </v-list-item-action>
      </v-list-item>

    </template>
  </v-list>
</template>
<script>
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname.vue";
import NutritionistListItemActions from "@/components/Chat/NutritionistListItemActions.vue";
import BrowserNotificationsConsent from "@/components/Chat/BrowserNotificationsConsent.vue";
import CrudClient from "@/services/CrudClient";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "NutritionistConversationsList",
	components: {
		BrowserNotificationsConsent,
		NutritionistListItemActions,
		UserAvatarFullname
	},
	data() {
		return {
			chatService: new CrudClient("Chat"),
			creatingConversation: false,
			showAllPatients: false,
			Conversations: [],
		};
	},
	computed: {
		...mapGetters([
			"isLoadingConversationView",
			"currentConversationUserId",
			"CombinedConversationView"]),

		ListedConversations() {
			return this.CombinedConversationView
				.filter(c => c.UserId === this.currentConversationUserId || this.showAllPatients || !c.IsArchived);
		}
	},
	async mounted() {
	},
	methods: {
		...mapActions([
			"SetCurrentConversationUserId"
		]),
	}
}
</script>
<style scoped lang="scss">

</style>
