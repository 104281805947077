var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"sendFileCard pa-2",class:{
		'blue-grey lighten-4': _vm.message.author === _vm.userId,
		'green lighten-4': _vm.message.author !== _vm.userId,
		'text-left': _vm.view === 'received',
		'text-right': _vm.view !== 'received',
		'widget-right': _vm.view !== 'received',
		'with-preview': _vm.message.attributes.FilePreviewUrl,
	},attrs:{"flat":""}},[_c('v-card-text',{staticClass:"font-weight-medium pa-0"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.view === "received" ? _vm.$t("common.fileReceived") : _vm.$t("common.fileSent"))+" ")]),(_vm.message.attributes.FilePreviewUrl)?_c('v-img',{staticClass:"mb-2",attrs:{"aspect-ratio":"1.2","src":_vm.message.attributes.FilePreviewUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}):_vm._e(),_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"18px","color":_vm.message.author === _vm.userId ? 'blue lighten-1' : 'green'}},[_vm._v("fas fa-paperclip")]),_c('a',{class:{
					'blue--text text--darken-2': _vm.message.author === _vm.userId,
					'green--text text--darken-3': _vm.message.author !== _vm.userId,
				},attrs:{"href":_vm.message.attributes.FileUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.message.attributes.FileDisplayName))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }