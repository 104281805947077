import SystemAdminLogViewer from "./LogViewer.vue"
import SystemAdminIndex from "./Index.vue"
import SystemAdminEmailTemplates from "./EmailTemplates.vue"

const routes = [{
	path: "/SystemSettings",
	component: SystemAdminIndex
},
{
	path: "/SystemSettings/LogViewer",
	component: SystemAdminLogViewer
},
{
	path: "/SystemSettings/EmailTemplates",
	component: SystemAdminEmailTemplates
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: "SystemAdmin"
	};
}
);

export default routes;
