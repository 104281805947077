<template>
	<div class="placeholder">
		<span
			class="skeleton-text line"
			:class="{
				shortenLastLine: shortenLastLine,
				justify: alignment == 'justify',
				alignleft: alignment == 'left',
				alignright: alignment == 'right',
				aligncenter: alignment == 'center',
			}"
			v-for="n in lines"
			:key="n"
		></span>
	</div>
</template>

<script>
export default {
	props: {
		lines: {
			type: Number,
			default: 4,
		},
		alignment: {
			type: String,
			default: "justify",
			validator: function (value) {
				// The value must match one of these strings
				return ["justify", "left", "right", "centred"].indexOf(value) !== -1;
			},
		},
		"shorten-last-line": {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style scoped lang="scss">
.placeholder {
	$skeletonColorDark: #90a4ae;
	$skeletonColorLight: #cfd8dc;
	.shine {
		background: $skeletonColorLight;
		-webkit-animation-duration: 3s;
		-webkit-animation-fill-mode: forwards;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-name: placeholderShimmer;
		-webkit-animation-timing-function: linear;

		animation-duration: 3s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeholderShimmer;
		animation-timing-function: linear;
	}
	@keyframes placeholderShimmer {
		0% {
			background-color: $skeletonColorLight;
		}
		80% {
			background-color: darken($skeletonColorLight, 15);
		}
		100% {
			background-color: $skeletonColorLight;
		}
	}
	.skeleton-text {
		@extend .shine;
		&.line {
			height: 21px;
			border-radius: 4px;
			display: block;
			margin-bottom: 10px;
			width: 100%;
			&.justify {
				width: 100%;
			}
			&.alignleft {
				&:nth-child(odd) {
					width: 95%;
				}
				&:nth-child(even) {
					width: 90%;
				}
			}
			&.alignright {
				&:nth-child(odd) {
					margin-left: 5%;
					width: 95%;
				}
				&:nth-child(even) {
					margin-left: 10%;
					width: 90%;
				}
				&.shortenLastLine {
					&:last-child:not(:first-child) {
						margin-left: 40%;
						width: 60%;
					}
				}
			}
			&.aligncenter {
				&:nth-child(odd) {
					margin-left: 5%;
					width: 90%;
				}
				&:nth-child(even) {
					margin-left: 10%;
					width: 80%;
				}
				&.shortenLastLine {
					&:last-child:not(:first-child) {
						margin-left: 20%;
						width: 60%;
					}
				}
			}
		}
		&.shortenLastLine {
			&:last-child:not(:first-child) {
				width: 60%;
			}
		}
	}
}
</style>
