<template>
	<v-dialog
		v-model="showDateTimeDialog"
		:close-on-content-click="false"
		max-width="290"
		v-bind="dialogProps"
	>
		<template v-slot:activator="{ on }" v-on="$listeners">
			<v-text-field
				v-on="on"
				:prepend-icon="prependIcon"
				readonly
				v-bind="$attrs"
				v-bind:value="value | formatDateTime"
				clearable
				@click:clear="onClearClicked"
			></v-text-field>
		</template>
		<v-date-picker
			v-show="!showTime"
			:locale="locale"
			v-model="selectedDate"
			@input="OnDateChanged"
			v-bind="datePickerProps"
			:first-day-of-week="1"
		></v-date-picker>
		<v-time-picker
			ref="timePicker"
			v-show="showTime"
			:locale="locale"
			:format="timeFormat"
			v-model="selectedTime"
			v-bind="timePickerProps"
			:allowed-minutes="allowedStep"
			@click:minute="OnMinuteChanged"
		></v-time-picker>
	</v-dialog>
</template>

<script>
import moment from "moment";

export default {
	inheritAttrs: false,
	props: {
		value: {
			type: String,
			validator: function (value) {
				const regex = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}[T][0-9]{2}[:][0-9]{2}");

				return regex.test(value);
			},
		},
		locale: {
			type: String,
			default: "it-IT",
		},
		timeFormat: {
			type: String,
			default: "24hr",
		},
		dialogProps: { type: Object },
		textFieldProps: { type: Object },
		datePickerProps: { type: Object },
		timePickerProps: { type: Object },
	},
	data() {
		return {
			showDateTimeDialog: false,
			showTime: false,
			prependIcon: "fas fa-calendar-alt",
			selectedDate: null,
			selectedTime: null,
			allowedStep: (m) => m % 5 === 0,
		};
	},
	computed: {
		selectedDateTime() {
			if (this.selectedDate && this.selectedTime) { return this.selectedDate + "T" + this.selectedTime; }

			return null;
		},
	},
	watch: {
		value: {
			immediate: true,
			async handler(newVal) {
				if (newVal == null) {
					this.selectedDate = null;
					this.selectedTime = null;
					return;
				}

				const m = moment(newVal);

				this.selectedDate = m.format("YYYY-MM-DD");
				this.selectedTime = m.format("HH:mm");

				// this.$log.info(this.selectedDate);
				// this.$log.info(this.selectedTime);
			},
		},
		showDateTimeDialog: function (val) {
			!val && this.resetDialog();
		},
	},
	methods: {
		OnDateChanged() {
			this.showTime = true;
		},

		OnMinuteChanged() {
			if (this.selectedTime) {
				this.showDateTimeDialog = false;
				this.resetDialog();

				this.$emit("input", this.selectedDateTime);
				this.$emit("change", this.selectedDateTime);
			}
		},
		onClearClicked() {
			this.$emit("input", null);
			this.$emit("change", null);
		},

		resetDialog() {
			this.$log.debug("reset dialog called");
			this.$refs.timePicker.selecting = 1;
			this.showTime = false;
		},
	},
};
</script>
