<template>
	<div>
		<h1 class="nutriyou-h1 primary--text">FAQ</h1>

		<div v-for="section in FAQItems" :key="section.Header" class="mb-8">
			<h2 class="faqSectionHeader" :class="section.class">
				{{ section.Header }}
			</h2>
			<v-expansion-panels>
				<v-expansion-panel v-for="(item, i) in section.Items" :key="i">
					<v-expansion-panel-header>
						<span class="font-weight-bold"> {{ item.Title }}</span>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<span
							v-html='item.Text.split(/\r?\n/).filter((i) => i).map(s => `<p>${s}</p>`).join(" ")'></span>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</div>
</template>
<script>

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "Home",
	data() {
		return {
			FAQItems: [{
				Header: "Nutriyou",
				class: "ny-greenBg",
				Items: [
					{
						Title: "Cos'è Nutriyou?",
						Text: "Nutriyou ti permette di trovare il nutrizionista più adatto per te, in base alle tue preferenze e ai tuoi bisogni, che ti aiuterà a raggiungere i tuoi obiettivi con un percorso nutrizionale 100% online. A noi piace dire che troverai un nutrizionista “su misura per te”."
					},
					{
						Title:
							"Qual è la missione di Nutriyou?",
						Text: `Abbiamo creato Nutriyou e ci stiamo impegnando duramente per farlo crescere perché abbiamo in mente un unico obiettivo: 
					rendere semplice e conveniente trovare un nutrizionista su misura e ricevere un supporto nutrizionale di qualità.`
					},
					{
						Title: "Perché dovrei rivolgermi ad un nutrizionista abilitato?",
						Text: `Un nutrizionista abilitato è un professionista che può rispondere alle tue domande sull’alimentazione, aiutarti con l’organizzazione dei pasti settimanali, accompagnarti verso un miglior rapporto con il cibo e il corpo, tranquillizzarti sulle fake news, valutare che i tuoi bisogni in termini di nutrienti siano soddisfatti e tanto altro. 
					Iniziare un percorso con un nutrizionista non serve solo per perdere peso come si è soliti pensare ma è un aiuto sul quale puoi contare ogni volta che hai un obiettivo, un dubbio o una difficoltà nella gestione della tua alimentazione.`
					},
					{
						Title: "Un percorso di visite nutrizionali online è veramente efficace?",
						Text: `Certo. Durante una visita online puoi essere seguita/o dal tuo nutrizionista nello stesso modo di un visita di persona. Nel caso servissero delle misure, come ad esempio l’altezza o il peso, potrai misurarle in autonomia seguendo le indicazioni del tuo nutrizionista. In più una visita online ti evita il tempo e i costi dello spostamento ed è più facile da inserire nei tuoi impegni giornalieri. Infatti i nutrizionisti Nutriyou sono disponibili anche in pausa pranzo, la sera o nel weekend. Insomma quando è comodo per te.
Questo è fondamentale perché un percorso che ha l’obiettivo di cambiare le tue abitudini porta a dei risultati duraturi solo se riesci a seguirlo con continuità nel tempo.`
					},
					{
						Title: "Perché dovrei affidarmi a Nutriyou?",
						Text: `Secondo noi Nutriyou ha 3 vantaggi principali:
- È semplice e veloce trovare il tuo nutrizionista su misura… ci pensa Nutriyou ;)
- Il prezzo è conveniente e trasparente.
- Ci impegniamo a selezionare i professionisti presenti sul sito e a garantire la qualità del servizio.
Infatti i nutrizionisti che trovi su Nutriyou non si sono registrati in modo autonomo ma li abbiamo conosciuti uno a uno, abbiamo verificato le loro informazioni e tutti hanno superato i nostri criteri di selezione. Ti spieghiamo quali sono nella sezione “I nutrizionisti di Nutriyou”, la trovi più in basso!`
					},
					{
						Title: "In quali casi Nutriyou non va bene per me?",
						Text: `I nutrizionisti Nutriyou ti aiutano a migliorare le tue abitudini alimentari e il tuo stile di vita per permetterti di raggiungere i tuoi obiettivi di benessere. Se invece hai bisogno di trattare una patologia con una dieta specifica, cioè ti serve una “dietoterapia” richiesta da un medico, ci spiace ma non fa parte del servizio di Nutriyou.
Alcuni esempi di patologie che richiedono una dietoterapia sono: nefropatia, cirrosi epatica, diabete mellito di tipo 1, fenilchetonuria.
Se hai dei dubbi su quello che ti serve, scrivici pure via mail (supporto@nutriyou.it) o clicca sul pulsante WhatsApp e cercheremo di consigliarti la strada migliore.`
					}
				]
			}, {
				Header: "Come Funziona",
				class: "ny-blueBg",
				Items: [{
					Title: "Come funziona Nutriyou?",
					Text: `Come prima cosa compila il questionario cliccando sul pulsante “VAI AL QUESTIONARIO” che trovi in alto a destra nella homepage.
Grazie alle tue risposte il nostro algoritmo di matching troverà il nutrizionista più adatto in base alle tue caratteristiche e ai tuoi bisogni. Solitamente servono meno di 24 ore e una volta trovato riceverai una email di conferma.
A questo punto il tuo nutrizionista ti scriverà nella chat dell’Area Personale entro 1-2 giorni lavorativi per fissare un primo incontro gratuito. In questo incontro potrai conoscerlo, parlare dei tuoi obiettivi e decidere se iniziare con lui il tuo percorso nutrizionale.
Un suggerimento: controlla regolarmente anche nello SPAM, le email sono dispettose!`
				}, {
					Title: "QUANTO COSTA una visita online con il mio nutrizionista?",
					Text: `L’incontro conoscitivo iniziale è gratuito e dura circa 20 minuti fino ad un massimo di 30 minuti, in base a come andrà la conversazione con il nutrizionista.
Le successive visite online, che durano tutte 50 minuti, hanno un costo fisso di 49€ e si pagano di volta in volta prima della visita.`
				}, {
					Title: "QUANTO DURA una visita?",
					Text: "Tutte le visite hanno la stessa durata (50 minuti) e lo stesso prezzo (49€)."
				}, {
					Title: "QUANDO si pagano le visite?",
					Text: `Per pagare la tua visita avrai tempo fino a 2 giorni prima dell’appuntamento, in caso contrario la prenotazione verrà annullata e dovrai fissarla nuovamente.
Per pagare ti bastano meno di 2 minuti: vai nella tua Area Personale e in “I prossimi appuntamenti” clicca su “PAGA ORA” nel prossimo evento che è in attesa di pagamento. Non puoi sbagliare 😉
Può capitare di dimenticarsi, per questo ti manderemo delle mail nei giorni precedenti la tua visita per ricordarti. Un consiglio che ti diamo: effettua il pagamento subito dopo che ti sarai accordato con il tuo nutrizionista, in modo da non dover tenere a mente questo impegno!
Se hai dubbi o hai bisogno di una mano riguardo i pagamenti scrivici pure via mail (supporto@nutriyou.it) o clicca sul pulsante WhatsApp che trovi in basso a destra in ogni pagina del sito.`
				}, {
					Title: "Posso spostare o cancellare una visita?",
					Text: `Si, in caso di imprevisto puoi spostare o cancellare una visita ma ti chiediamo di comunicarlo al tuo nutrizionista almeno 2 giorni prima in modo da decidere insieme un nuovo orario. Riceverai un rimborso completo del tuo pagamento solo nel caso queste tempistiche siano rispettate.
Ci dispiace essere inflessibili ma i nutrizionisti Nutriyou sono molto impegnati e questo è il preavviso minimo che serve per riorganizzare la loro agenda. Noi ci impegniamo affinché il loro tempo non venga sprecato. Grazie per la collaborazione! 🙂
Se hai dubbi o hai bisogno di una mano scrivici pure via mail (supporto@nutriyou.it) o clicca sul pulsante WhatsApp che trovi in basso a destra in ogni pagina.`
				}, {
					Title: "Come posso prenotare una visita?",
					Text: "Puoi scrivere al tuo nutrizionista in Chat nella tua Area Personale su www.nutriyou.it. Per rendere tutto più semplice ti consigliamo di fissare la tua prossima visita al termine di quella attuale mentre sei ancora in videochiamata con il tuo nutrizionista."
				},
				{
					Title: "Posso scegliere liberamente gli orari per i miei incontri?",
					Text: "Sì. Ti metterai d’accordo con il tuo nutrizionista visita per visita ma non temere, una delle informazioni che l’algoritmo utilizza per trovare il tuo nutrizionista è la corrispondenza tra gli orari in cui siete disponibili quindi non dovresti avere problemi ad accordarti."
				},
				{
					Title: "Una volta prenotata la visita, come mi collego alla videochiamata?",
					Text: "Trovi tutto nella tua Area Personale, ti basterà cliccare sul pulsante “VAI ALLA VIDEOCHIAMATA”. In questo modo si aprirà la pagina del suo studio virtuale e ti basterà scrivere il tuo nome e “bussare”. Il tuo nutrizionista sarà là che ti aspetta!"
				},
				{
					Title: "Quante visite farò durante il mio percorso nutrizionale?",
					Text: "Il numero degli incontri dipenderà dai tuoi obiettivi e da come andranno le cose durante il tuo percorso. In conclusione questa è una cosa che vedrai insieme al tuo nutrizionista. Per molte persone vanno bene 2 visite al mese nel periodo iniziale per poi passare ad 1 visita al mese per proseguire."
				},
				{
					Title: "Posso cambiare il nutrizionista che mi è stato assegnato?",
					Text: "Sì, non è un problema. Può capitare di non trovarsi bene con una persona. In questo caso basta che ci scrivi a supporto@nutriyou.it e faremo il possibile per trovare la soluzione giusta per te."
				},
				{
					Title: "La fattura dalla visita online si può detrarre come spesa sanitaria(detrazione IRPEF 19%)?",
					Text: `Assolutamente sì. Tutte le fatture emesse dal servizio Nutriyou sono detraibili in quanto sono considerate delle Prestazioni Sanitarie di Assistenza Specifica. Infatti ci serviranno il tuo codice fiscale e il tuo indirizzo di residenza per permetterci di comunicare la tua prestazione al portale online dell’Agenzia delle Entrate. Qualora volessi negare il consenso ti chiediamo di farcelo presente, prima di iniziare le visite, scrivendo a supporto@nutriyou.it.
					La fattura detraibile la riceverai i primi giorni del mese successivo alla tua visita.`
				}],
			},
			{
				Header: "I Nutrizionisti di Nutriyou",
				class: "ny-roseBg",
				Items: [
					{
						Title: "Chi sono i nutrizionisti di Nutriyou e che qualifiche hanno?",
						Text: `Per prima cosa ci teniamo a dirti questo: la nostra priorità è la qualità del servizio che ricevono gli utilizzatori di Nutriyou, come potresti essere te.
I nutrizionisti presenti su Nutriyou sono ovviamente al centro di tutto e proprio per questo li selezioniamo seguendo i nostri criteri di selezione. Questi criteri riguardano 3 aspetti: l’approccio, la formazione e l’esperienza. Te li spieghiamo meglio nel prossimo punto.
Lo standard che chiediamo per poter essere presenti su Nutriyou è quindi ben più alto dei requisiti minimi richiesti dallo Stato Italiano. Questa è uno dei nostri impegni verso il paziente.`
					},
					{
						Title: "Quali sono i criteri di selezione dei nutrizionisti Nutriyou?",
						Text: `Parliamo con ogni nutrizionista che si candida e verifichiamo personalmente le sue informazioni. Oltre a questo, ogni professionista presente su Nutriyou soddisfa i nostri 3 criteri di selezione. Solo così possiamo dire che si tratta di un Nutrizionista DOC 😉
1. VALORI - I nutrizionisti che troverai su Nutriyou condividono i nostri 3 valori: centralità delle persone, approccio personalizzato, gradualità del percorso. Per noi non sono solo parole: l’obiettivo di Nutriyou non è di essere un “semplice” servizio che facilita la ricerca di un professionista, questo lo fanno già decine di altri siti, ma vogliamo portante un cambiamento in meglio nella cultura di questo settore.
2. FORMAZIONE -  Sempre per garantire un’alta qualità del servizio, queste sono le figure professionali in ambito sanitario che accettiamo come candidati: Dietista, Biologo con LM-61 (Laurea Magistrale in Scienze della Nutrizione Umana) e Medico Dietologo (Medico Chirurgo con scuola di specializzazione in Scienza dell’Alimentazione).
3. ESPERIENZA - Minimo 2 anni dimostrati di pratica come libero professionista.
Inoltre verifichiamo che ogni nutrizionista sia regolarmente iscritto all’Albo del suo Ordine professionale e sia coperto da un’adeguata assicurazione professionale.`
					},
					{
						Title: "Chi sarà il mio nutrizionista?",
						Text: "Il tuo nutrizionista verrà selezionato grazie all’aiuto del nostro algoritmo. Il suo lavoro è di confrontare le tue esigenze e preferenze emerse dal questionario con la preparazione e la disponibilità dei nutrizionisti Nutriyou. Quando trova il nutrizionista su misura per te, si può partire!"
					},
					{
						Title: "Come posso vedere il profilo del nutrizionista che mi è stato assegnato?",
						Text: "Ogni nutrizionista ha una pagina profilo visibile pubblicamente dove sono scritte tutte le informazioni che lo riguardano. Il link alla pagina del tuo nutrizionista lo trovi nella mail di notifica del matching che ti abbiamo inviato oppure nella tua Area Personale oppure direttamente nella pagina “I NUTRIZIONISTI DI NUTRIYOU” che trovi nella homepage oppure clicca qua www.nutriyou.it/nutrizionisti."
					}

				]
			},
			{
				Header: "Supporto Tecnico",
				class: "ny-yellowBg",
				Items: [
					{
						Title: "Cosa mi serve per utilizzare Nutriyou?",
						Text: "Per utilizzare il servizio ti basta un telefono o computer con una connessione ad internet, un’email che leggi spesso, una videocamera e, consiglio nostro, anche delle cuffiette."
					},
					{
						Title: "Devo installare software specifici per utilizzare Nutriyou?",
						Text: "Non devi scaricare nessun software. Nutriyou funziona attraverso le pagine web che apri con il tuo browser (Chrome, Safari, Edge, Firefox, Opera, ecc)."
					},
					{
						Title: "Come vi posso contattare?",
						Text: "Puoi usare la pagina del sito “CONTATTACI” (https://www.nutriyou.it/contatti/) oppure inviare una mail a supporto@nutriyou.it oppure cliccare sul pulsante WhatsApp che trovi in basso a destra in ogni pagina."
					},
					{
						Title: "Come recupero la mia password?",
						Text: "Se non ricordi la tua password o l’hai persa puoi richiederne una nuova cliccando su questo link: https://www.nutriyou.it/password/."
					},
					{
						Title: "Posso cancellare il mio account?",
						Text: "Se vuoi cancellare il tuo account contattaci tramite una delle modalità possibili: pagina “CONTATTACI” (https://www.nutriyou.it/contatti/), l’email supporto@nutriyou.it oppure il pulsante WhatsApp che trovi in basso a destra in ogni pagina."
					}

				]
			}
			]
		};
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/AppStyle.scss";

.faqSectionHeader{
	padding: 10px 20px;
	margin-bottom: 10px;
	color: white;
	font-family: $font_Title;
}
</style>
<i18n>
	{
		"it":{
			"pageTitle": "Come Funziona e domande frequenti"
		}
	}
</i18n>
