<template>
	<v-select
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', $event)"
		:loading="loadingItems"
		:label="$attrs.label || $t('nutriyou.bookingType')"
		:items="bookingTypes"
		item-text="Name"
		item-value="BookingTypeId"
	></v-select>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			loadingItems: false,
			bookingTypes: [],
			// Service in data on purpose, it gives error when initialized in created in the initial value case
			BookingTypesService: new CrudClient("BookingTypes"),
		};
	},
	props: {
		value: { type: Number, default: null },
	},
	watch: {
		value: {
			immediate: true,
			handler: async function (newVal) {
				if (!newVal) return;
				this.$log.debug("watch new val");

				// Programmatic value set
				if (this.bookingTypes.length === 0) {
					await this.LoadBookingTypes();
				}
			},
		},
	},
	created() {},
	async mounted() {
		await this.LoadBookingTypes();
	},
	methods: {
		...mapActions(["snackError"]),

		async LoadBookingTypes() {
			try {
				this.loadingItems = true;
				const res = await this.BookingTypesService.Get();
				this.bookingTypes = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingItems = false;
			}
		},
	},
};
</script>
