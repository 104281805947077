<template>
	<div class="limitWidth">
		<h3 class="text-center mb-3 primary--text nutriyou-h2">Ora vai a confermare l'indirizzo email! 👍</h3>
		<p class="pageP">
			Grazie per aver compilato il questionario! Per scoprire chi è il nutrizionista su misura per te
			<span class="font-weight-bold">vai nella tua casella di posta, apri la nostra email e clicca su “Verifica
				la tua email”
			</span>.
		</p>
		<p class="pageP">
			Non ti verrà assegnato un nutrizionista finché non avrai confermato l’indirizzo email, quindi è molto
			importante farlo al più presto!

		</p>

		<v-alert color="nyblueBackgroud">
			<p class="pageP ma-3">
				Se non trovi la nostra email prova a controllare anche nello SPAM, a volte finisce lì. Se non è neanche
				lì,
				contattaci tramite una delle modalità possibili ossia la pagina
				<router-link to="/ContactUs" class="font-weight-bold primary--text">“CONTATTACI”</router-link>
				,
				l’email <span class="font-weight-bold primary--text">supporto@nutriyou.it</span>
				oppure il pulsante WhatsApp che trovi in basso a destra in ogni pagina.
			</p>
		</v-alert>
		<p class="pageP">
			Buon percorso nutrizionale!

		</p>
	</div>
</template>
<script>
export default {
	metaInfo() {
		return {
			title: "Hai completato il questionario!",
			meta: [{ name: "description", content: "Hai completato il questionario! Il nostro algoritmo è già al lavoro" }],
		};
	},
	mounted() {
		this.$gtag.event("conversion", { send_to: "AW-11476017435/3wcSCKqc-aQZEJvCmOAq" });
	}
}
</script>
<style lang="scss">
.pageP{
	font-family: "Poppins",
		sans-serif;
	font-size: 20px;;
	text-align: center;
	margin-bottom: 40px;

	@media all and (max-width: 900px) {
font-size: 16px;
	text-align: left;
	margin-bottom: 30px;
		}
}

.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
</style>
