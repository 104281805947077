<template>
  <v-list-item
    v-if="NotificationsAvailable &&
          NotificationPermission === 'default' &&
          !userPermissionGiven
        "
    class="pa-0 chatConversation blue lighten-3"
    @click="requestNotificationPermission"
  >
    <v-list-item-content>
      <div class="px-3 py-2">
        <v-avatar size="50" color="white">
          <v-icon size="30" color="blue lighten-3">fas fa-bell-slash</v-icon>
        </v-avatar>
        <span class="font-weight-medium ml-4">{{
            $t("enableDesktopNotifications")
          }}</span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
	name: "BrowserNotificationsConsent",
	computed: {
		NotificationsAvailable() {
			return "Notification" in window;
		},

		NotificationPermission() {
			return Notification.permission;
		}
	},
	data() {
		return {
			userPermissionGiven: false,
		};
	},
	methods: {
		async requestNotificationPermission() {
			if (!("Notification" in window)) {
				return;
			}
			await Notification.requestPermission();

			this.userPermissionGiven = true;
		},
	}
}
</script>
<i18n>
{
  "it": {
    "enableDesktopNotifications": "Abilita notifiche"
  }
}
</i18n>
