<template>
	<div class="home">
		<div class="mx-5">
			<h1
				class="nutriyou-h2 mt-1 mb-1 text-center primary--text"
				style="margin-top: 100px"
			>
				Entra nel team di Nutriyou
			</h1>

			<iframe
				data-tally-src="https://tally.so/embed/nrjkrp?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
				loading="lazy"
				width="100%"
				height="322"
				frameborder="0"
				marginheight="0"
				marginwidth="0"
				title="Paziente_1 (matching) - OK"
			></iframe>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "Home",
	data() {
		return { currentEvent: null };
	},

	computed: {
		...mapGetters(["SasToken"]),
	},
	mounted() {
		const tallyScript = document.createElement("script");
		tallyScript.setAttribute("src", "https://tally.so/widgets/embed.js");
		document.head.appendChild(tallyScript);
	},

	methods: {
		changeVal(val) {
			this.$log.debug("change: " + val);
		},
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Nutriyou",
		"pageMetaDescription": " Il nutrizionista online su misura per te"
	}
}
</i18n>
