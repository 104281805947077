import Vue from "vue"
import Vuex from "vuex"

import userData from "./userdata"
import notifications from "./notifications"
import chat from "./chat"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		userData: userData,
		notifications: notifications,
		chat: chat
	}
})
