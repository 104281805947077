<template>
	<v-dialog
		:value="value"
		max-width="500px"
		@keydown.esc="$emit('input', false)"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formBillingProfile"
			:formContent="formBillingProfile"
			:initialValues="BillingProfileDetails"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			@submitForm="AddOrSaveBillingProfile"
			:showCancelButton="false"
			:locali18n="$i18n"
		>
			<template slot="card-title">
				<div
					class="primary white--text px-5 py-3 d-flex align-center justify-space-between"
				>
					<span class="headline" style="word-break: normal">{{
						Header || $t("addBillingProfile_Header")
					}}</span>
					<v-btn icon dark @click="$emit('input', false)">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import CrudFormData from "@/utilities/CrudFormData.js";
import CrudClient from "@/services/CrudClient/";
import metadata from "@/mixins/metadata";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";

export default {
	components: { SimpleFormCard },
	mixins: [metadata],
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		BillingProfileId: { type: Number },
		UserId: { type: String },
		Header: { type: String },
	},
	data() {
		return {
			formBillingProfile: new CrudFormData("formBillingProfile", [
				"RagioneSociale",
				"CodiceFiscale",
				// "PartitaIva",
				"Address",
				{
					FieldName: "ComuneId",
					type: "ItalianCityPicker",
				},
				"PostalCode"
			]),
			BillingProfileDetails: {},
		};
	},
	watch: {
		value: async function (val) {
			if (!val) this.ClearForm();
		},
		BillingProfileId: {
			immediate: true,
			handler: async function (val) {
				// this.$log.debug("watch BillingProfileId");
				// this.$log.debug(val);

				if (val) {
					this.itemDetails = await this.LoadDetails(val);
				}
			},
		},
	},
	created() {
		if (this.UserId) {
			this.BillingProfileService = new CrudClient("User/BillingProfiles");
		} else {
			this.BillingProfileService = new CrudClient("User/BillingProfilesSelf");
		}
	},
	async mounted() {
		await this.LoadMetadata(this.BillingProfileService);
		this.metadata.CodiceFiscale.ClientValidators += "|required";
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSaveBillingProfile(dto) {
			try {
				this.formBillingProfile.FormLoading = true;

				dto.UserId = this.UserId;
				if (this.mode === 1) {
					dto.IsActive = true;
					await this.BillingProfileService.PostCustom(null, dto, null, false);
				} else {
					await this.BillingProfileService.Patch(this.BillingProfileId, dto, false);
				}
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				this.$emit("updated", this.BillingProfileId);
				this.ClearForm();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.formBillingProfile.FormLoading = false;
			}
		},

		async LoadDetails(id) {
			try {
				this.formBillingProfile.FormLoading = true;

				this.BillingProfileDetails = await this.BillingProfileService.GetCustom(id);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotLoadDetails") });
			} finally {
				this.formBillingProfile.FormLoading = false;
			}
		},

		ClearForm() {
			if (this.$refs.formBillingProfile) this.$refs.formBillingProfile.ResetForm();
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addBillingProfile_Header": "Dati di Fatturazione",
		"NotActive": "Non attivo",
		"formBillingProfile":{
			"RagioneSociale": "Nome completo (con secondo nome) e Cognome",
			"CodiceFiscale": "Codice Fiscale",
			"PartitaIva": "Partita IVA",
			"Address": "Indirizzo",
			"ComuneId": "Città",
			"IsActive": "Attivo",
			"PostalCode": "CAP"
		},
		"alertMissingBillingInfo": "Dati di fatturazione mancanti"
	}
}
</i18n>
