<template>
	<v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
		<v-card>
			<!-- <v-toolbar dark :color="options.color" dense flat>
				<v-toolbar-title class="white--text"></v-toolbar-title>
			</v-toolbar> -->
			<v-card-title>
				{{ title }}
			</v-card-title>
			<v-card-text v-show="!!message" class="mb-2" v-html="message"></v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn
					color="grey"
					text
					@click="cancel"
					class="text-capitalize"
					:class="{ 'width-xs-100': custom1 || custom2 }"
					>{{ $t("common.cancel") }}</v-btn
				>

				<v-btn
					class="text-capitalize width-xs-100 ml-0 ml-sm-2 my-1 my-sm-0"
					:class="custom1.class || options.color"
					v-if="custom1"
					@click="onCustomClick(custom1)"
					>{{ custom1.text }}</v-btn
				>

				<v-btn
					class="text-capitalize width-xs-100 ml-0 ml-sm-2 mb-1 mb-sm-0"
					:class="custom2.class || options.color"
					v-if="custom2"
					@click="onCustomClick(custom2)"
					>{{ custom2.text }}</v-btn
				>

				<v-btn
					:color="options.color"
					v-if="!disableDefaultConfirm"
					@click="agree"
					class="text-capitalize"
					>{{ $t("common.confirm") }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
/**
 * https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d#file-confirm-vue
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */

export default {
	name: "ConfirmDialog",
	props: {
		disableDefaultConfirm: { type: Boolean, default: false },
		custom1: { type: Object },
		custom2: { type: Object },
	},
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		message: null,
		title: null,
		options: {
			color: "primary",
			width: 290,
		},
	}),
	methods: {
		open(title, message, options) {
			this.dialog = true;
			this.title = title;
			this.message = message;
			this.options = Object.assign(this.options, options);
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},

		agree() {
			this.resolve(true);
			this.dialog = false;
		},

		onCustomClick(custom) {
			this.resolve(custom.value);
			this.dialog = false;
		},

		cancel() {
			this.resolve(false);
			this.dialog = false;
		},
	},
};
</script>
