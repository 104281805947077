import i18n from "@/i18n.js"

export default {
	state: {
		snack: {
			Text: "",
			Color: "",
			Header: "",
			Icon: ""
		}
	},
	mutations: {
		SET_SNACK_MESSAGE: (state, snack) => {
			state.snack = snack
		},

	},

	actions: {
		snackResetNotification(context) {
			context.commit("SET_SNACK_MESSAGE", {
				Text: "",
				Color: "",
				Header: "",
				Icon: ""
			});
		},

		snackCustomNotification(context, {
			Text,
			Color,
			Header,
			Icon
		}) {
			context.commit("SET_SNACK_MESSAGE", {
				Text,
				Color,
				Header,
				Icon
			});
		},

		snackSuccess(context, {
			Text,
			Color,
			Header,
			Icon
		}) {
			context.commit("SET_SNACK_MESSAGE", {
				Text,
				Color: Color || "success",
				Header: Header || i18n.t("snackHeader.success"),
				Icon: Icon || "fas fa-check"
			});
		},

		snackError(context, {
			Text,
			Color,
			Header,
			Icon
		}) {
			context.commit("SET_SNACK_MESSAGE", {
				Text,
				Color: Color || "error",
				Header: Header || i18n.t("snackHeader.error"),
				Icon: Icon || "fas fa-exclamation-triangle"
			});
		},

		snackMetadataError(context) {
			context.commit("SET_SNACK_MESSAGE", {
				Text: i18n.t("common.error.MetadataLoadingFailedMessage"),
				Color: "error",
				Header: i18n.t("common.error.MetadataLoadingFailedHeader"),
				Icon: "fas fa-exclamation-triangle"
			});
		},

		snackInfo(context, {
			Text,
			Color,
			Header,
			Icon
		}) {
			context.commit("SET_SNACK_MESSAGE", {
				Text,
				Color: Color || "info",
				Header: Header || i18n.t("snackHeader.info"),
				Icon: Icon || "far fa-info-circle"
			});
		}

	}
}
