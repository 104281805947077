<template>
	<v-chip v-bind="$attrs" :color="Status.Color" dark>
		{{ Status.Name }}
	</v-chip>
</template>

<script>
import { BookingTypesEnum } from "./nutriYouBookingTypesEnum";
export default {
	created() {
		this.BookingTypesEnum = BookingTypesEnum;
	},
	data() {
		return {};
	},
	computed: {
		Status() {
			switch (this.StatusId) {
				case this.BookingTypesEnum.FREE:
					return { Name: this.$t("FREE"), Color: "primary" };
				case this.BookingTypesEnum.NORMAL:
					return { Name: this.$t("NORMAL"), Color: "purple darken-2" };
				case this.BookingTypesEnum.MEALPLAN:
					return { Name: this.$t("MEALPLAN"), Color: "blue-grey darken-1" };
			}

			return "-";
		},
	},

	props: {
		StatusId: {
			type: Number,
			required: true,
		},
	},
};
</script>

<style></style>
<i18n>
{
	"it":{
		"FREE": "Incontro Conoscitivo",
		"NORMAL": "Visita Nutrizionale",
		"MEALPLAN": "Consegna e spiegazione del piano"
	}
}
</i18n>
