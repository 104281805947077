var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.TallyForm)?_c('div',{staticClass:"white pa-3"},_vm._l((_vm.FieldsView),function(field){return _c('div',{key:field.key,staticClass:"mb-3"},[_c('div',[_c('h5',{staticClass:"text-h6"},[_vm._v(_vm._s(field.label))]),(
				field.type === 'INPUT_TEXT' ||
				field.type === 'TEXTAREA' ||
				field.type === 'INPUT_EMAIL' ||
				field.type === 'INPUT_NUMBER' ||
				field.type === 'INPUT_PHONE_NUMBER' ||
				field.type === 'HIDDEN_FIELDS'
			)?_c('div',[_vm._v(" "+_vm._s(field.value || "--")+" ")]):(field.type === 'CHECKBOXES')?_c('div',_vm._l((field.childItems.filter(c => c.value)),function(child){return _c('div',{key:child.key},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(child.value ? "fas fa-check-square" : " far fa-square"))]),_vm._v(" "+_vm._s(child.label.replace(field.label, ""))+" ")],1)}),0):(field.type === 'MULTIPLE_CHOICE' || field.type === 'DROPDOWN')?_c('div',_vm._l((field.options.filter(c => c.id === field.value)),function(child){return _c('div',{key:child.id},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(child.id === field.value ? "fas fa-dot-circle" : " far fa-circle"))]),_vm._v(" "+_vm._s(child.text)+" ")],1)}),0):_c('div',[_vm._v(" else "+_vm._s(field)+" ")])])])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }