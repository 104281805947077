<template>
	<div v-if="TallyForm" class="white pa-3">
		<div v-for="field in FieldsView" :key="field.key" class="mb-3">
			<div>
				<h5 class="text-h6">{{ field.label }}</h5>
				<div v-if="
					field.type === 'INPUT_TEXT' ||
					field.type === 'TEXTAREA' ||
					field.type === 'INPUT_EMAIL' ||
					field.type === 'INPUT_NUMBER' ||
					field.type === 'INPUT_PHONE_NUMBER' ||
					field.type === 'HIDDEN_FIELDS'
				">
					{{ field.value || "--" }}
				</div>
				<div v-else-if="field.type === 'CHECKBOXES'">
					<div v-for="child in field.childItems.filter(c => c.value)" :key="child.key">
						<v-icon small color="primary">{{
							child.value ? "fas fa-check-square" : " far fa-square"
						}}</v-icon>

						{{ child.label.replace(field.label, "") }}
					</div>
				</div>
				<div v-else-if="field.type === 'MULTIPLE_CHOICE' || field.type === 'DROPDOWN'">
					<div v-for="child in field.options.filter(c => c.id === field.value)" :key="child.id">
						<v-icon small color="primary">{{
							child.id === field.value ? "fas fa-dot-circle" : " far fa-circle"
						}}</v-icon>

						{{ child.text }}
					</div>
				</div>
				<div v-else>
					else
					{{ field }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		TallyForm: { type: Object },
		AdminView: { type: Boolean, default: true }
	},
	computed: {
		FieldsView() {
			let mainFields = this.TallyForm.data.fields.filter(
				(f) =>
					f.type !== "CHECKBOXES" ||
					(f.type === "CHECKBOXES" && f.options !== undefined)
			);

			mainFields.forEach((field) => {
				if (field.type === "CHECKBOXES") {
					field.childItems = this.TallyForm.data.fields.filter(
						(f) =>
							f.type === "CHECKBOXES" &&
							f.options === undefined &&
							f.key.startsWith(field.key)
					);
				}
			});

			if (!this.AdminView) {
				const hideInputTypes = ["HIDDEN_FIELDS", "INPUT_PHONE_NUMBER", "INPUT_EMAIL"];

				mainFields = mainFields.filter(f => !hideInputTypes.includes(f.type));
			}

			return mainFields;
		}
	},
	methods: {
		// formatFieldsData(fields) {
		// 	const mainFields = fields.filter(
		// 		(f) =>
		// 			f.type !== "CHECKBOXES" ||
		// 			(f.type === "CHECKBOXES" && f.options !== undefined)
		// 	);

		// 	mainFields.forEach((field) => {
		// 		if (field.type === "CHECKBOXES") {
		// 			field.childItems = fields.filter(
		// 				(f) =>
		// 					f.type === "CHECKBOXES" &&
		// 					f.options === undefined &&
		// 					f.key.startsWith(field.key)
		// 			);
		// 		}
		// 	});

		// 	return mainFields;
		// },
	},
};
</script>
