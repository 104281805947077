const routes = [{
	path: "/AreaNutritionists",
	component: () => import(/* webpackChunkName: "NutritionistsArea" */ "./Index.vue")
},
{
	path: "/AreaNutritionists/Patients",
	component: () => import(/* webpackChunkName: "NutritionistsArea" */ "./Patients.vue")
},
{
	path: "/AreaNutritionists/Bookings",
	component: () => import(/* webpackChunkName: "NutritionistsArea" */ "./Bookings.vue")
},
{
	path: "/AreaNutritionists/Calendar",
	component: () => import(/* webpackChunkName: "NutritionistsArea" */ "./Calendar.vue")
},
{
	path: "/AreaNutritionists/Bookings/:BookingId",
	component: () => import(/* webpackChunkName: "NutritionistsArea" */ "./BookingDetails.vue")
},
{
	path: "/AreaNutritionists/Bookings/Patient/:UserId",
	component: () => import(/* webpackChunkName: "EventsView" */ "./Bookings.vue")
},
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["Nutritionist"]
	};
}
);

export default routes;
