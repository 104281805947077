<template>
	<div class="staticContentViewer">
		<vue-headful
			v-if="ShowHeader"
			:title="StaticContentItem.Title"
			:description="StaticContentItem.MetaDescription"
		/>

		<v-card tile class="elevation-2">
			<v-card flat v-if="ShowCoverimage && !StaticContentLoaded" key="shine">
				<v-responsive aspect-ratio="2.75" class="shine"></v-responsive>
			</v-card>
			<div v-else>
				<v-img
					v-if="StaticContentItem.ImageFilePath"
					:src="StaticContentItem.ImageFilePath[imageVersion]"
				></v-img>
				<div
					class="articleCoverCaption"
					v-html="StaticContentItem.ImageCaption"
					v-if="StaticContentItem.ImageFilePath && StaticContentItem.ImageCaption"
				></div>
			</div>
			<v-container>
				<v-row>
					<v-col cols="12" class="pa-5">
						<div v-if="ShowBreadcrumbs">
							<v-breadcrumbs
								:items="BreadcrumbsStart"
								class="pl-0"
								v-if="StaticContentLoaded"
							>
                <template v-slot:divider>
                  <v-icon class="secondary--text text--lighten-2"
                  >fas fa-caret-right</v-icon
                  >
                </template>
							</v-breadcrumbs>
							<div class="skeleton-text" v-else></div>
						</div>
						<h1 class="font-weight-black">
							<span v-text="StaticContentItem.Title" v-if="StaticContentLoaded"></span>
							<span class="skeleton-text header" v-else></span>
						</h1>

						<div
							class="ArticleBody text-justify"
							v-html="StaticContentItem.Text"
							v-if="StaticContentLoaded"
						></div>
						<skeleton-text v-else :lines="6" alignment="left"></skeleton-text>

						<div class="mt-4" v-if="ShowTags && StaticContentLoaded">
							<!--TODO: Navigate to tags Page-->
							<v-chip
								color="grey darken-3"
								text-color="white"
								label
								v-for="tag in Tags"
								:key="tag.TagId"
								class="mr-2 mt-2"
							>
								{{ tag.TagName }}
								<v-icon right>fas fa-tag</v-icon>
							</v-chip>
						</div>
						<div v-if="ShowTags && !StaticContentLoaded">
							<span
								v-for="n in 4"
								:key="n"
								class="skeleton-text chip short my-2 mr-2"
							></span>
						</div>

						<slot name="afterContent"></slot>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	metaInfo() {
		return {
			title: this.StaticContentItem.Title,
			meta: [{ name: "description", content: this.StaticContentItem.MetaDescription }],
		};
	},
	name: "StaticContentViewer",
	props: {
		ContentId: Number,

		ShowBreadcrumbs: {
			type: Boolean,
			default: false,
		},

		ShowCoverimage: {
			type: Boolean,
			default: false,
		},

		ShowTags: {
			type: Boolean,
			default: false,
		},

		ShowHeader: {
			type: Boolean,
			default: false,
		},

		BreadcrumbsStart: {
			type: Array,
			default: () => [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		},
	},
	data: () => ({
		StaticContentLoaded: false,
		StaticContentItem: {},
		StaticContentService: new CrudClient("StaticContent"),
		Tags: [],
	}),
	computed: {
		imageVersion: function () {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 400;
				case "sm":
					return 800;
				case "md":
				case "lg":
				case "xl":
				default:
					return 1200;
			}
		},
	},
	methods: {
		...mapActions(["snackError"]),

		async LoadStaticContent(ContentId) {
			try {
				this.StaticContentLoaded = false;

				this.StaticContentItem = await this.StaticContentService.GetSelectedFields(
					ContentId,
					"Title, Text, ImageFilePath, FriendlyUrl, MetaDescription, ImageCaption"
				);

				if (this.ShowTags) {
					this.Tags = await this.StaticContentService.GetTags(ContentId);
				}
				if (this.ShowBreadcrumbs) {
					this.BreadcrumbsStart.push({
						text: this.StaticContentItem.Title,
						disabled: true,
						exact: true,
						to: this.StaticContentItem.FriendlyUrl,
					});
				}

				this.StaticContentLoaded = true;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError.LoadStaticContent") });
				this.$captureError(error);
			}
		},
	},
	async mounted() {
		this.$log.info("StaticContentViewer Mounted");
		await this.LoadStaticContent(this.ContentId);
	},
};
</script>
<style lang="scss">

.ArticleBody {
	img {
		display: block;
		max-width: 100%;
	}

	p{
		margin-bottom: 30px;
	}
}
</style>
