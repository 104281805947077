<template>
	<div>
		<StripeCheckout
			ref="checkoutRef"
			mode="payment"
			:pk="publishableKey"
			:line-items="lineItems"
			:success-url="successURL"
			:cancel-url="cancelURL"
			:session-id="sessionId"
			@loading="(v) => (stripeLoading = v)"
			:email="customerEmail"
		>
		</StripeCheckout>

		<v-btn
			@click="redirectToStripe"
			v-bind="$attrs"
			v-on="$listeners"
			:loading="stripeLoading || dataLoading"
		>
			<v-icon left>fab fa-cc-stripe</v-icon>
			Paga Ora</v-btn
		>
	</div>
</template>
<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import CrudClient from "@/services/CrudClient/";
import { mapGetters, mapActions } from "vuex";
export default {
	components: {
		StripeCheckout,
	},
	props: {
		booking: {
			type: Object,
			required: true,
		},
	},
	data() {
		this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
		return {
			stripeLoading: false,
			dataLoading: false,
			lineItems: [],
			successURL: null,
			cancelURL: null,
			customerEmail: null,
			clientReferenceId: null,
			sessionId: null,
			metaPixelInitiateCheckoutEventSent: false,
		};
	},

	mounted() {
		this.$log.debug(this.UserProfile);

		this.customerEmail = this.UserProfile.AspNetUser.Email;
	},
	computed: {
		...mapGetters(["UserProfile"]),
	},
	created() {
		this.StripePaymentsService = new CrudClient("StripePayments");
		this.BillingProfilesSelfService = new CrudClient(
			"User/BillingProfilesSelf"
		);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async redirectToStripe() {
			// this.$log.debug(this.$refs.checkoutRef);
			// You will be redirected to Stripe's secure checkout page
			try {
				this.dataLoading = true;

				if (!this.metaPixelInitiateCheckoutEventSent) {
					this.metaPixelInitiateCheckoutEventSent = true;
					window.fbq("track", "InitiateCheckout");
				}

				const billingProfileCount = await this.BillingProfilesSelfService.Count({
					filter: "IsActive:true",
					addApplicationId: true
				});

				this.$log.debug(billingProfileCount);
				if (billingProfileCount === 0) {
					this.$emit("RequireBillingProfile");
					return;
				}

				this.sessionId = (await this.StripePaymentsService.Get(
					this.booking.BookingId + "/CreateSession"
				)).SessionId;

				this.$refs.checkoutRef.redirectToCheckout();
			} catch (err) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(err);
			} finally {
				this.dataLoading = false;
			}
		},
	},
};
</script>
