<template>
	<v-banner
		v-if="
			message.attributes && message.attributes.MessageType === 'PaymentCompleted'
		"
		tile
		class="messageBanner"
		:single-line="!$vuetify.breakpoint.xs"
		:class="{ mobile: $vuetify.breakpoint.xs }"
	>
		<p class="bannerBody">
			<!-- Nutriz -->
			<template v-if="view === 'received'">
				<router-link
					:to="'/AreaNutritionists/Bookings/' + message.attributes.BookingId"
				>
					{{ formattedMessage }}
				</router-link>
			</template>

			<!-- Patient -->
			<template v-if="view === 'sent'">
				<router-link :to="'/Patients/Agenda/' + message.attributes.BookingId">
					{{ formattedMessage }}
				</router-link>
			</template>
		</p>
	</v-banner>
</template>
<script>
import moment from "moment";

export default {
	props: {
		message: {
			type: Object,
			required: true,
		},

		view: {
			type: String,
			required: true,

			validator: (value) => {
				return ["sent", "received"].indexOf(value) !== -1;
			},
		},
	},
	computed: {
		formattedMessage() {
			moment.locale("it");
			const m = moment(this.message.attributes.BookingTime);
			const formattedTime = m.format("L") + " " + m.format("LT");

			return this.$t("paymentReceived", { date: formattedTime });
		}
	}
};
</script>

<i18n>
{
	"it": {
		"paymentReceived": "Pagamento ricevuto, appuntamento del {date} è confermato!"
	}
}
</i18n>
