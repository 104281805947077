<template>
	<span>
			<span>
				<TallyPatientFormDialog
						v-model="showTallyForm"
						:UserId="tallyFormUserId"
				></TallyPatientFormDialog>
				<CreateBookingDialog
						v-model="showCreateBookingDialog"
						:PatientUserId="createBookingUserId"
						@bookingCreated="OnBookingCreated"
					>
				</CreateBookingDialog>
			</span>
				<v-icon v-if="loading">fad fa-spinner fa-spin</v-icon>
			<v-menu>
				<template v-slot:activator="{ on, attrs }">
					<v-icon v-show="!loading" v-bind="attrs"  v-on="on">fas fa-ellipsis-v</v-icon>
				</template>
				<v-list>
					<v-list-item @click="showTallyPatientForm">
						<v-list-item-action><v-icon color="primary">fas fa-fw fa-info</v-icon></v-list-item-action>
						<v-list-item-title> Info Del Paziente</v-list-item-title>
					</v-list-item>
					<v-list-item @click="showBookingDialog">
						<v-list-item-action><v-icon color="primary">fas fa-fw fa-plus-square</v-icon></v-list-item-action>
						<v-list-item-title>Nuovo Appuntamento</v-list-item-title>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item v-if="!Conversation.IsArchived"
									@click="ArchiveConversation()">
						<v-list-item-action><v-icon>fas fa-inbox-in</v-icon></v-list-item-action>
						<v-list-item-title>Archivia</v-list-item-title>
					</v-list-item>
					<v-list-item v-else
									@click="UnarchiveConversation()">
						<v-list-item-action><v-icon>fas fa-inbox-out</v-icon></v-list-item-action>
						<v-list-item-title>Sposta in inbox</v-list-item-title>
					</v-list-item>

				</v-list>
			</v-menu>
	</span>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import CreateBookingDialog from "@/views/Nutritionists/components/CreateBookingDialog";
import TallyPatientFormDialog from "@/views/Nutritionists/components/TallyPatientFormDialog";

export default {
	components: { CreateBookingDialog, TallyPatientFormDialog },
	data() {
		return {
			PatientsService: new CrudClient("CurrentNutritionist/Patients"),
			chatService: new CrudClient("Chat"),
			showCreateBookingDialog: false,
			showTallyForm: false,
			createBookingUserId: null,
			tallyFormUserId: null,
			loading: false
		};
	},
	props: {
		Conversation: {
			type: Object,
			required: true,
		},
	},
	watch: {
		showCreateBookingDialog: function (val) {
			if (!val) {
				this.createBookingUserId = null;
			}
		},
	},
	mounted() {
		this.$log.debug(this.Conversation.UserId);
	},
	methods: {
		showTallyPatientForm() {
			this.tallyFormUserId = this.Conversation.UserId;
			this.showTallyForm = true;
		},
		showBookingDialog() {
			this.createBookingUserId = this.Conversation.UserId;
			this.showCreateBookingDialog = true;
		},
		async OnBookingCreated() {
			this.createBookingUserId = null;
			this.showCreateBookingDialog = false;
		},
		async ArchiveConversation() {
			try {
				this.loading = true;
				await this.PatientsService.Post(this.Conversation.UserId, null, "Archive");
				this.$emit("conversationArchived");
			} finally {
				this.loading = false;
			}
		},

		async UnarchiveConversation() {
			try {
				this.loading = true;
				await this.PatientsService.Post(this.Conversation.UserId, null, "Unarchive");
				this.$emit("conversationUnarchived");
			} finally {
				this.loading = false;
			}
		}
	}
}
</script>
