<template>
	<v-fade-transition>
		<v-card
			v-show="!file.completed"
			flat
			color="blue-grey lighten-4"
			class="uploadingFileCard mt-3 pa-2 text-right widget-right"
		>
			<v-card-text class="font-weight-medium pa-0">
				<div class="mb-2">{{ $t("common.fileUploading") }}</div>
				<div class="mb-2">
					<v-icon class="mr-3" color="blue lighten-2" size="18px"
						>fas fa-paperclip</v-icon
					>
					<span>{{ file.name }}</span>
				</div>
				<div class="d-flex align-center">
					<v-progress-linear
						color="secondary"
						:value="file.progress"
					></v-progress-linear>
				</div>
			</v-card-text>
		</v-card>
	</v-fade-transition>
</template>

<script>
export default {
	props: {
		file: Object,
	},
	data: () => ({}),
	methods: {
		cancelUpload() {
			this.file.cancelSource.cancel("AbortUpload");
		},
	},
};
</script>

<style scoped>
.uploadingFileCard {
	width: 51%;
	float: right;
	border-radius: 10px !important;
	display: flex;
	align-items: flex-end;
}
</style>
