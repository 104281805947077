import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import vuetify from "@/plugins/vuetify"
import i18n from "./i18n"
import logger from "@/utilities/logger"
import VueGtag from "vue-gtag";
import LoadScript from "vue-plugin-load-script";
import visibility from "vue-visibility-change";

import "@/utilities/veeValidate"

import VueMeta from "vue-meta"
import ReadMore from "vue-read-more";

// import AccountService from "@/services/account"

import "@/filters/filters";
import {
	getParameterByName,
	routerGuardsSetup
} from "./routerGuardsSetup";

import SkeletonTable from "@/components/Shared/UI/Skeleton/Table"
import SkeletonText from "@/components/Shared/UI/Skeleton/Text"
// import VueZoomer from "vue-zoomer"

Vue.use(visibility);

Vue.use(LoadScript);
Vue.use(ReadMore);
Vue.use(VueMeta);
Vue.component("skeleton-table", SkeletonTable);
Vue.component("skeleton-text", SkeletonText);

// Vue.use(VueZoomer)

Vue.config.productionTip = false

async function applicationStart() {
	logger.$log.info("Application Start - Admin");

	try {
		// const applicationId = getParameterByName("ApplicationId");
		const applicationId = "C091A4A5-E083-4D76-A1A1-9ABA0210A031";
		store.state.userData.selectedApplicationId = applicationId;

		const instantAccessToken = getParameterByName("InstantLoginToken");
		if (instantAccessToken) {
			await store.dispatch("refreshTokenFromInstantLoginToken", instantAccessToken);
		}

		const utmSource = getParameterByName("utm_source");
		const utmMedium = getParameterByName("utm_medium");
		const utmCampaign = getParameterByName("utm_campaign");

		// logger.$log.debug({ utmSource, utmMedium, utmCampaign });
		store.state.userData.utmParameters = { utmSource, utmMedium, utmCampaign };
		// router.push("/Events");

		// try to refresh token
		await store.dispatch("RefreshAccessToken", true);

		setInterval(async () => {
			await store.dispatch("RefreshAccessToken", false);
		}, 30 * 60 * 1000);

		await store.dispatch("LoadUserData");
	} catch (error) {
		logger.$log.error("LoadUserData error:");
		logger.$log.error(error);
	}

	// router guards and application mount are activated after UserData is fetched from server
	routerGuardsSetup(router);

	// if (process.env.NODE_ENV === "production") {
	Vue.use(VueGtag, {
		config: {
			id: "GTM-PQS4WVF"
		}
	}, router);
	// }

	new Vue({
		router,
		store,
		render: h => h(App),
		i18n,
		vuetify
	}).$mount("#app");

	window.addEventListener("storage", async() => {
		const token = JSON.parse(window.localStorage.getItem("user-token"));
		// await store.dispatch("SetRefreshToken", token);
		if (token) { store.commit("SET_AUTH_TOKEN", token); } else { store.commit("SIGN_OUT"); }
	});
}
(async () => {
	await applicationStart();
})();
