var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placeholder"},_vm._l((_vm.lines),function(n){return _c('span',{key:n,staticClass:"skeleton-text line",class:{
			shortenLastLine: _vm.shortenLastLine,
			justify: _vm.alignment == 'justify',
			alignleft: _vm.alignment == 'left',
			alignright: _vm.alignment == 'right',
			aligncenter: _vm.alignment == 'center',
		}})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }