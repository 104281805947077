var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({},'v-card',_vm.cardProps,false),[_vm._t("card-title",null,null,_vm.cardTitleProps),_c('v-card-text',[_vm._t("card-beforeForm"),(_vm.metadataLoaded)?_c('v-form',{attrs:{"value":_vm.value,"data-vv-scope":_vm.formContent.Scope},on:{"input":function($event){return _vm.$emit('input', $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm._submitForm.apply(null, arguments)}}},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.formContent.FormLoading,"z-index":4}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_vm._l((_vm.formContentSchema),function(field){return _c('v-row',{key:field.key,attrs:{"dense":""}},[_c('v-col',[_c(field.type,_vm._g(_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(field['v-validate']),expression:"field['v-validate']"}],tag:"component",attrs:{"type":field['input-type'],"error-messages":_vm.errors.collect(_vm.formContent.Scope + '.' + field.key),"hint":field['hint'] ||
							(field['v-validate'] && field['v-validate'].indexOf('required') >= 0
								? '* ' + _vm.$t('common.required')
								: null),"persistent-hint":field['persistent-hint'] ||
							(!field['hint'] &&
								field['v-validate'] &&
								field['v-validate'].indexOf('required') >= 0)},model:{value:(_vm.Values[field.key]),callback:function ($$v) {_vm.$set(_vm.Values, field.key, $$v)},expression:"Values[field.key]"}},'component',field,false),field['listeners']))],1)],1)})],2):_vm._e(),_vm._t("card-afterForm")],2),_vm._t("card-actions",function(){return [(_vm.showDivider)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),_c('CardActionAddSave',{attrs:{"mode":_vm.mode,"showCancelButton":_vm.showCancelButton,"loading":_vm.formContent.FormSendingData,"addButtonProps":_vm.addButtonProps,"saveButtonProps":_vm.saveButtonProps,"closeButtonProps":_vm.closeButtonProps},on:{"addOrSaveClicked":_vm._submitForm,"closeClicked":_vm._closeClicked}},[_c('template',{slot:"btnAdd"},[_vm._t("btnAdd")],2),_c('template',{slot:"btnClose"},[_vm._t("btnClose")],2),_c('template',{slot:"btnSave"},[_vm._t("btnSave")],2)],2)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }