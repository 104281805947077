<template>
	<div class="home">
		<div>
			<iframe
				:data-tally-src="`https://tally.so/embed/nGxXVO?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`"
				loading="lazy" width="100%" height="322" frameborder="0" marginheight="0" marginwidth="0"
				title="Paziente_1 (matching) - OK"></iframe>
		</div>

	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "Home",
	data() {
		return { currentEvent: null };
	},

	computed: {
		...mapGetters(["SasToken", "UtmParameters"]),
	},
	mounted() {
		const tallyScript = document.createElement("script");
		tallyScript.setAttribute("src", "https://tally.so/widgets/embed.js");
		document.head.appendChild(tallyScript);
	},

	methods: {
		changeVal(val) {
			this.$log.debug("change: " + val);
		},
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Nutriyou",
		"pageMetaDescription": " Il nutrizionista online su misura per te"
	}
}
</i18n>
