<template>
	<div>
		<v-row>
			<v-col cols="12" sm="6" class="offset-sm-3">
				<v-card>
					<v-breadcrumbs :items="breadcrumbsItems">
            <template v-slot:divider>
              <v-icon class="secondary--text text--lighten-2"
              >fas fa-caret-right</v-icon
              >
            </template>
					</v-breadcrumbs>
					<div class="nyroseLight px-6 py-2">
						<div class="LoginFormTitle">{{ $t("pageTitle") }}</div>
						<div class="LoginFormSubtitle">{{ $t("titleDec") }}</div>
					</div>

					<div class="pa-5">
						<form @submit.prevent="submit" v-if="!showSuccessMessage">
							<v-text-field v-model="username" :label="$t('yourEmail')" prepend-icon="fas fa-envelope"
								v-validate="'required|email'" :error-messages="errors.collect('username')"
								data-vv-name="username" :data-vv-as="$t('username')"></v-text-field>

							<v-btn type="submit" color="primary" :loading="loading" block x-large>{{
								$t("btnRecoverPassword")
								}}</v-btn>

							<v-alert v-model="showAuthError" type="error">{{
								authErrorMessage
								}}</v-alert>
						</form>

						<v-alert v-model="showSuccessMessage" type="success">{{
							$t("checkEmail")
							}}</v-alert>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import Account from "@/services/account";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("titleDec") }],
		};
	},
	name: "Login",
	$_veeValidate: { validator: "new" },
	data: () => ({
		username: "",
		loading: false,
		showAuthError: false,
		showSuccessMessage: false,
		authErrorMessage: "",
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			},
			{
				text: "Login",
				disabled: false,
				exact: true,
				to: "/Login",
			},
		],
	}),
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/RecoverPassword",
		});
	},
	methods: {
		async submit() {
			this.$log.info("submit called");
			if (this.loading) return;
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) return;
				this.loading = true;
				this.showAuthError = false;
				this.showSuccessMessage = false;

				const dto = {};
				dto.Email = this.username;
				const res = await Account.tryResetPassword(dto);

				if (!res) {
					this.showSuccessMessage = true;
				} else if (res.Message) {
					this.showAuthError = true;
					this.authErrorMessage = res.Message;
				}
			} catch (err) {
				this.$captureError(err);
				this.showAuthError = true;
				this.authErrorMessage = err.Message;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<i18n>
{
	"it": {
		"pageTitle": "Recupero Password",
		"titleDec": "Inserisci il tuo indirizzo email",
		"yourEmail": "La tua email",
		"username": "email",
		"btnRecoverPassword": "Recupera Password",
		"checkEmail": "Controlla la tua email per reimpostare la tua password"
	},
	"en": {
		"pageTitle": "Recover Password",
		"titleDec": "Please enter your email address, you'll receive an email with recover instructions",
		"yourEmail": "Your email",
		"btnRecoverPassword": "Recover Password",
		"checkEmail": "Check your email for resetting your password"
	}
}
</i18n>
