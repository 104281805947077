"use strict";

export default {
	_STORAGE_KEY: "user-token",

	// removes auth tokens from localStorage and sessionStorage
	clearAuthToken() {
		localStorage.removeItem(this._STORAGE_KEY);
		sessionStorage.removeItem(this._STORAGE_KEY);
	},

	// writes auth token to localStorage or sessionStorage
	persistAuthResult(authResult, rememberMe) {
		// if remember me is specified, write to the specified storage
		if (rememberMe !== undefined) {
			this.clearAuthToken();
			(rememberMe === true ? localStorage : sessionStorage).setItem(this._STORAGE_KEY,
				JSON.stringify(authResult));
		} else {
			// otherwise overwrite the existing item
			if (sessionStorage.getItem(this._STORAGE_KEY)) {
				sessionStorage.setItem(this._STORAGE_KEY, JSON.stringify(authResult));
			} else {
				localStorage.setItem(this._STORAGE_KEY, JSON.stringify(authResult));
			}
		}
	},

	getAuthToken() {
		const storedAuthData = sessionStorage.getItem(this._STORAGE_KEY) || localStorage.getItem(this._STORAGE_KEY);

		if (storedAuthData) {
			return JSON.parse(storedAuthData);
		}

		return null;
	}

}
