import Vue from "vue"
import VeeValidate from "vee-validate"

import VueI18n from "vue-i18n";
import validationMessagesEn from "vee-validate/dist/locale/en";
import validationMessagesIt from "vee-validate/dist/locale/it";

import i18n from "@/i18n.js";

Vue.use(VueI18n);

Vue.use(VeeValidate, {
	i18n,
	dictionary: {
		en: validationMessagesEn,
		it: validationMessagesIt,
	}
});
