<template>
	<div>
		<v-col cols="12" sm="6" class="offset-sm-3">
			<v-card>
				<v-breadcrumbs :items="breadcrumbsItems">
          <template v-slot:divider>
            <v-icon class="secondary--text text--lighten-2"
            >fas fa-caret-right</v-icon
            >
          </template>
				</v-breadcrumbs>

				<v-alert
					dark
					type="warning"
					border="left"
					v-if="this.$route.query.InvalidAccessToken"
				>
					<h4 class="text-h5">Link Scaduto</h4>
					Per accedere al servizio deve effettuare il login.
					<br />Se non ricordi la tua password
					<router-link to="/RecoverPassword" class="font-weight-bold white--text"
						>recupera le tue credenziali</router-link
					>
					.
				</v-alert>

				<login-form
					:ReturnUrl="this.$route.query.ReturnUrl"
					:ApplicationId="this.$route.query.ApplicationId"
				></login-form>
			</v-card>
		</v-col>
	</div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "Login",
	components: { LoginForm },
	data: () => ({
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			},
			{
				text: "Login",
				disabled: true,
				exact: true,
				to: "/Login",
			},
		],
	}),
};
</script>

<i18n>
{
	"it": {
		"pageTitle": "Accedi al tuo account",
		"pageMetaDescription": "Accedi al tuo account"
	}
}
</i18n>
