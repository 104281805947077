import NutritionistProfile from "./NutritionistProfile"
import Nutritionists from "./Nutritionists"
import Home from "./Home.vue"
import FindYourNutritionist from "./FindYourNutritionist.vue"
import NutritionistJoin from "./NutritionistJoin.vue"
import Contacts from "./Contacts"
import VerifyEmail from "./VerifyEmail"
import FAQ from "./FAQ"
import ThankYouPage from "./ThankYouPage"
import BlogIndex from "./Blog/Index.vue"
import StaticContentViewer from "./StaticContentView.vue"
import StaticContentViewerFromFriendlyUrl from "./StaticContentViewFromFriendlyUrl.vue"

const routes = [{
	path: "/Nutritionists",
	component: Nutritionists
},
{
	path: "/Nutritionists/:friendyUrl",
	component: NutritionistProfile
},
{
	path: "/",
	name: "Home",
	component: Home
},
{
	path: "/Grazie",
	name: "ThankYouPage",
	component: ThankYouPage
},
{
	path: "/trova-il-tuo-nutrizionista",
	name: "FindYourNutritionist",
	component: FindYourNutritionist
},
{
	path: "/entra-nel-team",
	name: "NutritionistJoin",
	component: NutritionistJoin
},
{
	path: "/ContactUs",
	name: "Contacts",
	component: Contacts
},
{
	path: "/FAQ",
	name: "FAQ",
	component: FAQ
},
{
	path: "/VerifyEmail/:verificationToken",
	name: "VerifyEmail",
	component: VerifyEmail
},
{
	path: "/About",
	component: StaticContentViewer,
	props: {
		ContentId: 1 // databaseItemIds.staticContentId.about
	}
},
{
	path: "/Blog",
	component: BlogIndex,
	// props: {
	// 	ContentId: 1 // databaseItemIds.staticContentId.about
	// }
},
{
	path: "/Blog/:FriendlyUrl",
	component: StaticContentViewerFromFriendlyUrl,
	// props: {
	// 	ContentId: 1 // databaseItemIds.staticContentId.about
	// }
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: false,
	};
}
);

export default routes;
