<template>
	<v-card
    class="ny-chatwidget-green text-left chatWidget elevation-0"
    dark
    v-if="
		message.attributes && message.attributes.MessageType === 'BookingCreated'
	" :class="{ 'widget-right': view !== 'received' }">
		<v-card-text class="white--text" >
			<div class="mb-2 InfoBoxTitle">
				<span v-if="message.attributes.BookingTypeId === BookingTypesEnum.FREE">{{ $t("freeVisit") }}</span>
				<span v-if="message.attributes.BookingTypeId === BookingTypesEnum.NORMAL">{{ $t("normalVisit") }}</span>
				<span v-if="message.attributes.BookingTypeId === BookingTypesEnum.MEALPLAN">{{ $t("mealPlan") }}</span>
			</div>
			<div class="mb-2 poppins-regular Date" >
				<v-icon class="mr-2">far fa-calendar</v-icon>
				{{ message.attributes.BookingTime | formatDateTime }}
			</div>
			<div class="mb-2 poppins-regular InfoText" style=""
           v-if="view === 'received' && message.attributes.BookingTypeId !== BookingTypesEnum.FREE">
				ti ricordiamo di effettuare il pagamento entro 2 giorni dall’appuntamento, in caso contrario la prenotazione verrà annullata
			</div>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>

			<v-btn text :to="'/Patients/Agenda/' + message.attributes.BookingId" v-if="view === 'received'">{{
				$t("common.details") }}</v-btn>
			<v-btn text :to="'/AreaNutritionists/Bookings/' + message.attributes.BookingId" v-else>{{ $t("common.details")
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";

export default {
	created() {
		this.BookingTypesEnum = BookingTypesEnum;
	},
	props: {
		message: {
			type: Object,
			required: true,
		},

		view: {
			type: String,
			required: true,

			validator: (value) => {
				return ["sent", "received"].indexOf(value) !== -1;
			},
		},
	},
// 	1	Incontro Conoscitivo
// 2	Visita Nutrizionale
};
</script>

<i18n>
{
	"it": {
		"normalVisit": "Visita Nutrizionale Creata!",
		"freeVisit": "Incontro Conoscitivo Creato!",
		"mealPlan": "Consegna e spiegazione del piano Creata!"
	}
}
</i18n>
