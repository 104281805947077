const routes = [{
	path: "/Admin/Content",
	component: () => import(/* webpackChunkName: "CMS" */ "./Index.vue")
},
{
	path: "/Admin/Content/StaticContent",
	component: () => import(/* webpackChunkName: "CMS" */ "./StaticContent.vue")
},
{
	path: "/Admin/Content/Galleries",
	component: () => import(/* webpackChunkName: "CMS" */ "./Galleries.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["BusinessAdmin"]
	};
}
);

export default routes;
