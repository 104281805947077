<template>
	<div>
		<h1 class="nutriyou-h1 primary--text">{{ $t("pageTitle") }}</h1>

		<v-container>
			<v-row v-if="LoadingData">
				<v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="n in 12" :key="n">
					<v-skeleton-loader type="card"></v-skeleton-loader>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="item in items" :key="item.UserId">
					<v-card :to="'./Nutritionists/' + item.ProfilePageUrl" color="nyGreen">
						<v-card-text class="px-5">
							<v-img :src="item.ImageFileUrl" class="white rounded"></v-img>
						</v-card-text>
						<div class="text-center truncate px-3 mb-2">
							<span class="TitleFont primary--text" style="font-size:1.5rem;">
								{{ item.ApplicationUser.DisplayName }}</span>
						</div>
						<div class="text-center truncate px-3 pb-3" style="font-size:1.3rem;">
							{{ item.ProfessionalTitle }}
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import CrudClient from "@/services/CrudClient/";

import { mapActions } from "vuex";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 20,
		};
	},
	created() {
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.nutritionists"),
			disabled: true,
			exact: true,
			to: "/Nutritionists",
		});

		await this.LoadNutritionists();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadNutritionists() {
			try {
				this.LoadingData = true;
				const res = await this.NutritionistsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "ProfessionalTitle,ImageFilePath,ProfilePageUrl,UserId",
					// filter: filter,
					// orderBy:
				});
				this.items = this.shuffle(res.Data);
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
		shuffle(array) {
			let currentIndex = array.length;
			let randomIndex;

			// While there remain elements to shuffle.
			while (currentIndex !== 0) {
				// Pick a remaining element.
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex--;

				// And swap it with the current element.
				[array[currentIndex], array[randomIndex]] = [
					array[randomIndex],
					array[currentIndex],
				];
			}

			return array;
		},
	},
};
</script>
<style></style>
<i18n>
	{
	"it":{
	"pageTitle": "I nutrizionisti di Nutriyou"
	}
	}
</i18n>
