class CrudFormData {
	constructor(formName, fields) {
		// TODO: remove after integrate
		this.Values = {};
		this.Scope = formName;
		this.Fields = fields;
		this.FormLoading = false;
		this.FormSendingData = false;
	}

	GetData() {
		const newItem = {};
		this.Fields.map(field => {
			const fieldName = (typeof field === "string") ? field : field.FieldName;

			newItem[fieldName] = this.Values[fieldName];
		});
		return newItem;
	}

	SetData(data) {
		// Setting the root element assures reactivity
		const formData = {};
		this.Fields.map(field => {
			const fieldName = (typeof field === "string") ? field : field.FieldName;
			formData[fieldName] = data[fieldName];
		});

		this.Values = formData;
	}

	ResetData() {
		Object.keys(this.Values).map(e => {
			this.Values[e] = null;
		});
	}
}

export default CrudFormData;
