import Vue from "vue"
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import logger from "@/utilities/logger"
import {
	HttpError

} from "@/services/HttpError";

import {
	RouterError
} from "@/services/RouterError";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://96b588a31ea344eebe5b8ab738fdfe4b@o299568.ingest.sentry.io/4504525603864576",
		release: "v2.build." + process.env.VUE_APP_RUN_NUMBER,
		environment: process.env.NODE_ENV,
		integrations: [new Integrations.Vue({
			Vue,
			attachProps: true
		})],
	});
}

// logger.$log.debug("Loading Sentry");

export function captureError(error) {
	logger.$log.error(error);

	Sentry.withScope(function(scope) {
		if (error instanceof HttpError) { scope.setContext("RequestDetails", error.data); }
		Sentry.captureException(error);
	});
}

export function captureRouterError(to) {
	logger.$log.error("Cannot find URL");
	logger.$log.error(to);

	Sentry.withScope(function(scope) {
		scope.setContext("RequestDetails", to);
		Sentry.captureException(new RouterError(to));
	});
}

Vue.prototype.$captureError = captureError;
