<template>
	<div
		:id="message.sid"
		:class="isSystemMessage ? 'systemMessage' : 'textMessage'"
	>
		<div
			v-if="!message.attributes.MessageType"
			class="pa-2 mb-1 messageBox Selectable"
			:class="{
				'blue-grey lighten-4': message.author === userId,
				'green lighten-4': message.author !== userId,
			}"
		>
			{{ message.body }}
		</div>

		<SendFileWidget
			:message="message"
			:userId="userId"
			v-if="message.attributes.MessageType === 'SendFile'"
			:view="viewAs"
		></SendFileWidget>

		<BookingCreated
			:message="message"
			:view="viewAs"
		></BookingCreated>
		<BookingModified
			:message="message"
			:view="viewAs"
		></BookingModified>
		<BookingCancelledWidget
			:message="message"
			:view="viewAs"
		>
		</BookingCancelledWidget>

		<BookingCompleted
			:message="message"
			:view="viewAs"
		>
		</BookingCompleted>

		<PaymentCompleted
			:message="message"
			:view="viewAs"
		>
		</PaymentCompleted>
		<BookingExpired
				:message="message"
				:view="viewAs"
		>
		</BookingExpired>

		<div class="MessageTimestamp grey--text text--darken-1 mb-2">
			{{ message.timestamp | formatLocalDateTime }}
			<v-icon
				size="12"
				class="blue--text text--lighten-1"
				v-if="
				message.liveConversation &&
					viewAs === 'sent' &&
					lastSeenMessageId &&
					message.index <= lastSeenMessageId
				"
				>fas fa-check</v-icon
			>
		</div>
	</div>
</template>

<script>
import BookingCreated from "./Widgets/BookingCreated";
import BookingCancelledWidget from "./Widgets/BookingCancelled.vue";
import BookingCompleted from "./Widgets/BookingCompleted";
import BookingModified from "./Widgets/BookingModified.vue";
import SendFileWidget from "./Widgets/SendFile";
import PaymentCompleted from "./Widgets/PaymentCompleted.vue";
import BookingExpired from "./Widgets/BookingExpired.vue";
export default {
	components: {
		BookingCreated,
		SendFileWidget,
		BookingCancelledWidget,
		BookingCompleted,
		BookingModified,
		PaymentCompleted,
		BookingExpired
	},
	props: {
		message: Object,
		userId: String,
		lastSeenMessageId: Number,
	},
	data: () => ({
	}),
	computed: {
		isSystemMessage() {
			const type = this.message.attributes.MessageType;
			if (type === "PaymentCompleted" ||
				type === "BookingExpired" ||
				type === "BookingCompleted") {
				return true;
			} else {
				return false;
			}
		},
		viewAs() {
			return this.message.author.toLowerCase() === this.userId.toLowerCase() ? "sent" : "received"
		}
	},
	methods: {

	},
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.messageBox {
	position: relative;
	border-radius: 10px;
	white-space: pre-line;
	display: flex;
	align-items: flex-start;
	width: fit-content;
	word-break: break-word;
	.readMore {
		p {
			margin: 0;
		}

		button {
			color: $primary;
		}
	}
	@media #{map-get($display-breakpoints, 'xs-only')} {
		&:hover {
			.messageActions {
				display: initial;
			}
		}
	}
}

.textMessage {
	display: flex;
	flex-direction: column;
	max-width: 60%;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    max-width: 80%;
  }
	@media #{map-get($display-breakpoints, 'xs-only')} {
		max-width: 95%;
	}
	.v-card {
		&.chatWidget {
			&.widget-right {
				margin-left: auto;
				margin-right: 0;
			}

    .InfoBoxTitle{
      font-family: $font_Title;
      font-size: 1.35rem;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-family: $font_Title;
        font-size:  1.25rem;
      }
    }

    .Date {
      font-size: 1.1rem;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size:  0.9rem;
      }
    }

      .InfoText{
        font-size: 1rem;

        @media #{map-get($display-breakpoints, 'xs-only')} {
          font-size:  0.9rem;
        }
      }

		}
	}
}

.systemMessage {
	align-self: normal;
	display: flex;
	flex-direction: column;
	.messageBanner {
		background: none !important;
		.v-banner {
			&__wrapper {
				border: 0 !important;
			}
			&__content {
				justify-content: center;
			}
			&__text {
				border: 0 !important;
				width: 100%;
				.bannerBody {
					display: flex;
					flex-direction: row;
					margin: 0;
					font-size: 18px;
					&:before,
					&:after {
						content: "";
						flex: 1 1;
						border-bottom: 1px solid map-get($grey, "lighten-1");
						margin: auto;
					}
					&:before {
						margin-right: 15px;
					}
					&:after {
						margin-left: 15px;
					}
					a {
						color: map-get($grey, "darken-3");
						&:hover {
							color: map-get($blue, "base");
						}
					}
				}
			}
		}
		&.mobile {
			.bannerBody {
				text-align: center;
			}
		}
	}
}

.MessageTimestamp {
	margin-top: 5px;
	font-size: 11px;
	display: flex;
	align-items: flex-start;
	width: fit-content;
	.v-icon {
		margin-left: 5px;
	}
}

</style>

<i18n>
{
	"it": {	}
}
</i18n>
