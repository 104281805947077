<template>
	<v-list>
		<v-list-item v-for="l in lines" :key="'l' + l">
			<v-list-item-action class="pr-1" v-if="avatar">
				<span class="skeleton-text chip icon"></span>
			</v-list-item-action>
			<v-list-item-content class="pr-1" v-for="c in columns" :key="'c' + c">
				<span class="skeleton-text"></span>
			</v-list-item-content>
			<v-list-item-action class="pr-2 ml-0" v-for="a in actions" :key="'a' + a">
				<span class="skeleton-text chip icon"></span>
			</v-list-item-action>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: "skeleton-table",
	props: {
		avatar: {
			type: Boolean,
			default: false,
		},
		columns: {
			type: Number,
			default: 1,
		},
		actions: {
			type: Number,
			default: 0,
		},
		lines: {
			type: Number,
			default: 5,
		},
	},
};
</script>
<style>
.skeleton-text {
	background-color: red;
	display: inline-block;
	width: 100%;
	min-height: 20px;
}
</style>
