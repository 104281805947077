<template>
	<v-input v-bind="$attrs" v-on="$listeners" class="input-rating">
		<v-rating
			dense
			small
			color="amber"
			background-color="amber"
			:value="value"
			@input="$emit('input', $event)"
		></v-rating>
	</v-input>
</template>
<script>
export default {
	props: ["value"]
};
</script>
